export const getSubscriptionPrice = (type, country) => {
  const stuPrices = [
    ["Aruba", 25],
    ["Afghanistan", 5],
    ["Angola", 10],
    ["Albania", 20],
    ["Andorra", 25],
    ["United Arab Emirates", 25],
    ["Argentina", 20],
    ["Armenia", 20],
    ["American Samoa", 25],
    ["Antigua and Barbuda", 25],
    ["Australia", 25],
    ["Austria", 25],
    ["Azerbaijan", 20],
    ["Burundi", 5],
    ["Belgium", 25],
    ["Benin", 10],
    ["Burkina Faso", 5],
    ["Bangladesh", 10],
    ["Bulgaria", 20],
    ["Bahrain", 25],
    ["Bahamas, The", 25],
    ["Bosnia and Herzegovina", 20],
    ["Belarus", 20],
    ["Belize", 20],
    ["Bermuda", 25],
    ["Bolivia", 10],
    ["Brazil", 20],
    ["Barbados", 25],
    ["Brunei Darussalam", 25],
    ["Bhutan", 10],
    ["Botswana", 20],
    ["Central African Republic", 5],
    ["Canada", 25],
    ["Switzerland", 25],
    ["Channel Islands", 25],
    ["Chile", 25],
    ["China", 20],
    ["Côte d’Ivoire", 10],
    ["Cameroon", 10],
    ["Congo, Dem. Republic", 5],
    ["Congo, Republic", 10],
    ["Colombia", 20],
    ["Comoros", 10],
    ["Cabo Verde", 10],
    ["Costa Rica", 20],
    ["Cuba", 20],
    ["Curaçao", 25],
    ["Cayman Islands", 25],
    ["Cyprus", 25],
    ["Czechia", 25],
    ["Germany", 25],
    ["Djibouti", 10],
    ["Dominica", 20],
    ["Denmark", 25],
    ["Dominican Republic", 20],
    ["Algeria", 10],
    ["Ecuador", 20],
    ["Egypt, Arab Republic", 10],
    ["Eritrea", 5],
    ["Spain", 25],
    ["Estonia", 25],
    ["Ethiopia", 5],
    ["Finland", 25],
    ["Fiji", 20],
    ["France", 25],
    ["Faroe Islands", 25],
    ["Micronesia, Fed. Sts.", 10],
    ["Gabon", 20],
    ["United Kingdom", 25],
    ["Georgia", 20],
    ["Ghana", 10],
    ["Gibraltar", 25],
    ["Guinea", 10],
    ["Gambia,", 5],
    ["Guinea-Bissau", 5],
    ["Equatorial Guinea", 20],
    ["Greece", 25],
    ["Grenada", 20],
    ["Greenland", 25],
    ["Guatemala", 20],
    ["Guam", 25],
    ["Guyana", 25],
    ["Hong Kong SAR, China", 25],
    ["Honduras", 10],
    ["Croatia", 25],
    ["Haiti", 10],
    ["Hungary", 25],
    ["Indonesia", 20],
    ["Isle of Man", 25],
    ["India", 10],
    ["Ireland", 25],
    ["Iran, Islamic Republic", 10],
    ["Iraq", 20],
    ["Iceland", 25],
    ["Israel", 25],
    ["Italy", 25],
    ["Jamaica", 20],
    ["Jordan", 10],
    ["Japan", 25],
    ["Kazakhstan", 20],
    ["Kenya", 10],
    ["Kyrgyz Republic", 10],
    ["Cambodia", 10],
    ["Kiribati", 10],
    ["St. Kitts and Nevis", 25],
    ["Korea, Republic", 25],
    ["Kuwait", 25],
    ["Lao PDR", 10],
    ["Lebanon", 10],
    ["Liberia", 5],
    ["Libya", 20],
    ["St. Lucia", 20],
    ["Liechtenstein", 25],
    ["Sri Lanka", 10],
    ["Lesotho", 10],
    ["Lithuania", 25],
    ["Luxembourg", 25],
    ["Latvia", 25],
    ["Macao", 25],
    ["St. Martin (French)", 25],
    ["Morocco", 10],
    ["Monaco", 25],
    ["Moldova", 20],
    ["Madagascar", 5],
    ["Maldives", 20],
    ["Mexico", 20],
    ["Marshall Islands", 20],
    ["North Macedonia", 20],
    ["Mali", 5],
    ["Malta", 25],
    ["Myanmar", 10],
    ["Montenegro", 20],
    ["Mongolia", 10],
    ["Northern Mariana Islands", 25],
    ["Mozambique", 5],
    ["Mauritania", 10],
    ["Mauritius", 20],
    ["Malawi", 5],
    ["Malaysia", 20],
    ["Namibia", 20],
    ["New Caledonia", 25],
    ["Niger", 5],
    ["Nigeria", 10],
    ["Nicaragua", 10],
    ["Netherlands", 25],
    ["Norway", 25],
    ["Nepal", 10],
    ["Nauru", 25],
    ["New Zealand", 25],
    ["Oman", 25],
    ["Pakistan", 10],
    ["Panama", 25],
    ["Peru", 20],
    ["Philippines", 10],
    ["Palau", 20],
    ["Papua New Guinea", 10],
    ["Poland", 25],
    ["Puerto Rico", 25],
    ["Korea, Dem. People's Republic", 5],
    ["Portugal", 25],
    ["Paraguay", 20],
    ["West Bank and Gaza", 20],
    ["French Polynesia", 25],
    ["Qatar", 25],
    ["Romania", 25],
    ["Russian Federation", 20],
    ["Rwanda", 5],
    ["Saudi Arabia", 25],
    ["Sudan", 5],
    ["Senegal", 10],
    ["Singapore", 25],
    ["Solomon Islands", 10],
    ["Sierra Leone", 5],
    ["El Salvador", 20],
    ["San Marino", 25],
    ["Somalia", 5],
    ["Serbia", 20],
    ["South Sudan", 5],
    ["São Tomé and Príncipe", 10],
    ["Suriname", 20],
    ["Slovak Republic", 25],
    ["Slovenia", 25],
    ["Sweden", 25],
    ["Eswatini", 10],
    ["Sint Maarten (Dutch part)", 25],
    ["Seychelles", 25],
    ["Syrian Arab Republic", 5],
    ["Turks and Caicos Islands", 25],
    ["Chad", 5],
    ["Togo", 5],
    ["Thailand", 20],
    ["Tajikistan", 10],
    ["Turkmenistan", 20],
    ["Timor-Leste", 10],
    ["Tonga", 20],
    ["Trinidad and Tobago", 25],
    ["Tunisia", 10],
    ["Türkiye", 20],
    ["Tuvalu", 20],
    ["Taiwan, China", 25],
    ["Tanzania", 10],
    ["Uganda", 5],
    ["Ukraine", 10],
    ["Uruguay", 25],
    ["United States", 25],
    ["Uzbekistan", 10],
    ["St. Vincent and the Grenadines", 20],
    ["Venezuela", 20],
    ["British Virgin Islands", 25],
    ["Virgin Islands (U.S.)", 25],
    ["Vietnam", 10],
    ["Vanuatu", 10],
    ["Samoa", 10],
    ["Kosovo", 20],
    ["Yemen Republic", 5],
    ["South Africa", 20],
    ["Zambia", 10],
    ["Zimbabwe", 10],
  ];
  const proPrices = [
    ["Aruba", 35],
    ["Afghanistan", 15],
    ["Angola", 20],
    ["Albania", 25],
    ["Andorra", 35],
    ["United Arab Emirates", 35],
    ["Argentina", 25],
    ["Armenia", 25],
    ["American Samoa", 35],
    ["Antigua and Barbuda", 35],
    ["Australia", 35],
    ["Austria", 35],
    ["Azerbaijan", 25],
    ["Burundi", 15],
    ["Belgium", 35],
    ["Benin", 20],
    ["Burkina Faso", 15],
    ["Bangladesh", 20],
    ["Bulgaria", 25],
    ["Bahrain", 35],
    ["Bahamas, The", 35],
    ["Bosnia and Herzegovina", 25],
    ["Belarus", 25],
    ["Belize", 25],
    ["Bermuda", 35],
    ["Bolivia", 20],
    ["Brazil", 25],
    ["Barbados", 35],
    ["Brunei Darussalam", 35],
    ["Bhutan", 20],
    ["Botswana", 25],
    ["Central African Republic", 15],
    ["Canada", 35],
    ["Switzerland", 35],
    ["Channel Islands", 35],
    ["Chile", 35],
    ["China", 25],
    ["Côte d’Ivoire", 20],
    ["Cameroon", 20],
    ["Congo, Dem. Republic", 15],
    ["Congo, Republic", 20],
    ["Colombia", 25],
    ["Comoros", 20],
    ["Cabo Verde", 20],
    ["Costa Rica", 25],
    ["Cuba", 25],
    ["Curaçao", 35],
    ["Cayman Islands", 35],
    ["Cyprus", 35],
    ["Czechia", 35],
    ["Germany", 35],
    ["Djibouti", 20],
    ["Dominica", 25],
    ["Denmark", 35],
    ["Dominican Republic", 25],
    ["Algeria", 20],
    ["Ecuador", 25],
    ["Egypt, Arab Republic", 20],
    ["Eritrea", 15],
    ["Spain", 35],
    ["Estonia", 35],
    ["Ethiopia", 15],
    ["Finland", 35],
    ["Fiji", 25],
    ["France", 35],
    ["Faroe Islands", 35],
    ["Micronesia, Fed. Sts.", 20],
    ["Gabon", 25],
    ["United Kingdom", 35],
    ["Georgia", 25],
    ["Ghana", 20],
    ["Gibraltar", 35],
    ["Guinea", 20],
    ["Gambia, The", 15],
    ["Guinea-Bissau", 15],
    ["Equatorial Guinea", 25],
    ["Greece", 35],
    ["Grenada", 25],
    ["Greenland", 35],
    ["Guatemala", 25],
    ["Guam", 35],
    ["Guyana", 35],
    ["Hong Kong SAR, China", 35],
    ["Honduras", 20],
    ["Croatia", 35],
    ["Haiti", 20],
    ["Hungary", 35],
    ["Indonesia", 25],
    ["Isle of Man", 35],
    ["India", 20],
    ["Ireland", 35],
    ["Iran, Islamic Republic", 20],
    ["Iraq", 25],
    ["Iceland", 35],
    ["Israel", 35],
    ["Italy", 35],
    ["Jamaica", 25],
    ["Jordan", 20],
    ["Japan", 35],
    ["Kazakhstan", 25],
    ["Kenya", 20],
    ["Kyrgyz Republic", 20],
    ["Cambodia", 20],
    ["Kiribati", 20],
    ["St. Kitts and Nevis", 35],
    ["Korea, Republic", 35],
    ["Kuwait", 35],
    ["Lao PDR", 20],
    ["Lebanon", 20],
    ["Liberia", 15],
    ["Libya", 25],
    ["St. Lucia", 25],
    ["Liechtenstein", 35],
    ["Sri Lanka", 20],
    ["Lesotho", 20],
    ["Lithuania", 35],
    ["Luxembourg", 35],
    ["Latvia", 35],
    ["Macao SAR, China", 35],
    ["St. Martin (French part)", 35],
    ["Morocco", 20],
    ["Monaco", 35],
    ["Moldova", 25],
    ["Madagascar", 15],
    ["Maldives", 25],
    ["Mexico", 25],
    ["Marshall Islands", 25],
    ["North Macedonia", 25],
    ["Mali", 15],
    ["Malta", 35],
    ["Myanmar", 20],
    ["Montenegro", 25],
    ["Mongolia", 20],
    ["Northern Mariana Islands", 35],
    ["Mozambique", 15],
    ["Mauritania", 20],
    ["Mauritius", 25],
    ["Malawi", 15],
    ["Malaysia", 25],
    ["Namibia", 25],
    ["New Caledonia", 35],
    ["Niger", 15],
    ["Nigeria", 20],
    ["Nicaragua", 20],
    ["Netherlands", 35],
    ["Norway", 35],
    ["Nepal", 20],
    ["Nauru", 35],
    ["New Zealand", 35],
    ["Oman", 35],
    ["Pakistan", 20],
    ["Panama", 35],
    ["Peru", 25],
    ["Philippines", 20],
    ["Palau", 25],
    ["Papua New Guinea", 20],
    ["Poland", 35],
    ["Puerto Rico", 35],
    ["Korea, Dem. People's Republic", 15],
    ["Portugal", 35],
    ["Paraguay", 25],
    ["West Bank and Gaza", 25],
    ["French Polynesia", 35],
    ["Qatar", 35],
    ["Romania", 35],
    ["Russian Federation", 25],
    ["Rwanda", 15],
    ["Saudi Arabia", 35],
    ["Sudan", 15],
    ["Senegal", 20],
    ["Singapore", 35],
    ["Solomon Islands", 20],
    ["Sierra Leone", 15],
    ["El Salvador", 25],
    ["San Marino", 35],
    ["Somalia", 15],
    ["Serbia", 25],
    ["South Sudan", 15],
    ["São Tomé and Príncipe", 20],
    ["Suriname", 25],
    ["Slovak Republic", 35],
    ["Slovenia", 35],
    ["Sweden", 35],
    ["Eswatini", 20],
    ["Sint Maarten (Dutch part)", 35],
    ["Seychelles", 35],
    ["Syrian Arab Republic", 15],
    ["Turks and Caicos Islands", 35],
    ["Chad", 15],
    ["Togo", 15],
    ["Thailand", 25],
    ["Tajikistan", 20],
    ["Turkmenistan", 25],
    ["Timor-Leste", 20],
    ["Tonga", 25],
    ["Trinidad and Tobago", 35],
    ["Tunisia", 20],
    ["Türkiye", 25],
    ["Tuvalu", 25],
    ["Taiwan, China", 35],
    ["Tanzania", 20],
    ["Uganda", 15],
    ["Ukraine", 20],
    ["Uruguay", 35],
    ["United States", 35],
    ["Uzbekistan", 20],
    ["St. Vincent and the Grenadines", 25],
    ["Venezuela, RB", 25],
    ["British Virgin Islands", 35],
    ["Virgin Islands (U.S.)", 35],
    ["Vietnam", 20],
    ["Vanuatu", 20],
    ["Samoa", 20],
    ["Kosovo", 25],
    ["Yemen, Republic", 15],
    ["South Africa", 25],
    ["Zambia", 20],
    ["Zimbabwe", 20],
  ];
  // Select the appropriate array based on the type
  const arrayToSearch =
    type === "prof" ? proPrices : type === "stu" ? stuPrices : null;

  if (!arrayToSearch) {
    throw new Error("Invalid type specified");
  }

  // Find the entry for the specified country
  const entry = arrayToSearch.find((item) => item[0] === country);

  // Return the subscription price if found, otherwise return a message or null
  return entry ? entry[1] : null;
};
