// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentPage_payment_page__e1MU6 {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.PaymentPage_payment_page__e1MU6 h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.PaymentPage_payment_page__e1MU6 p {
    margin-bottom: 20px;
}

.PaymentPage_subscription_details__A2qkD {
    margin-top: 20px;
}

.PaymentPage_subscription_details__A2qkD h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

.PaymentPage_subscription_details__A2qkD ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.PaymentPage_subscription_details__A2qkD li {
    margin-bottom: 10px;
}

.PaymentPage_subscription_details__A2qkD li span {
    font-weight: bold;
    margin-right: 10px;
}

.PaymentPage_initiate_payment_btn__teqb7 {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.PaymentPage_initiate_payment_btn__teqb7:hover {
    background-color: #0069d9;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Payment/PaymentPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".payment_page {\n    max-width: 600px;\n    margin: 40px auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n}\n\n.payment_page h1 {\n    font-size: 24px;\n    margin-bottom: 10px;\n}\n\n.payment_page p {\n    margin-bottom: 20px;\n}\n\n.subscription_details {\n    margin-top: 20px;\n}\n\n.subscription_details h2 {\n    font-size: 18px;\n    margin-bottom: 10px;\n}\n\n.subscription_details ul {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n}\n\n.subscription_details li {\n    margin-bottom: 10px;\n}\n\n.subscription_details li span {\n    font-weight: bold;\n    margin-right: 10px;\n}\n\n.initiate_payment_btn {\n    background-color: #007bff;\n    color: #ffffff;\n    border: none;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    border-radius: 5px;\n}\n\n.initiate_payment_btn:hover {\n    background-color: #0069d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment_page": `PaymentPage_payment_page__e1MU6`,
	"subscription_details": `PaymentPage_subscription_details__A2qkD`,
	"initiate_payment_btn": `PaymentPage_initiate_payment_btn__teqb7`
};
export default ___CSS_LOADER_EXPORT___;
