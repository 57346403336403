import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import toast from "react-hot-toast";

function PayPalButton(props) {
  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            value: props.amount,
            currency_code: "USD",
          },
        },
      ],
      items: [
        {
          name: `${props.type} Membership`,
          description: `Annual membership with Pines for ${props.type}`,
          quantity: 1,
          unit_amount: {
            currency_code: "USD",
            value: props.amount,
          },
        },
      ],
      shipping: {
        address_override: false,
      },
    });
  };

  const onApprove = (data, actions) => {
    //if payment successful, push data to DB
    return actions.order.capture().then((details) => {
      const txn = {
        paymentIntentId: details.purchase_units[0].payments.captures[0].id,
        amount: details.purchase_units[0].payments.captures[0].amount.value,
        currency:
          details.purchase_units[0].payments.captures[0].amount.currency_code,
        status: details.status,
        customerName: details.payer.name.given_name,
        customerEmail: details.payer.email_address,
        customerType: props.type,
      };
      axios
        .post(process.env.REACT_APP_BE_URI + "/paypal_webhook", { txn })
        .then((response) => {
          // console.log(response.data.message);
          window.location.href = "/paySuccess"; // route to successful payment page
        })
        .catch((error) => {
          toast.error(
            "Payment was successful, but there was an error updating the database. Please try again later.",
            txn.paymentIntentId
          );
          console.error("Error logging payment:", error); //log DB updation error to console
        });
    });
  };

  const onError = (err) => {
    console.error(err);
    window.location.href = "/subscriptionpayment"; // if payment fails, redirect back to make payment page
  };

  const styles = {
    shape: "rect",
    color: "blue",
    label: "pay",
    tagline: false,
    layout: "horizontal",
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        disableFunding: "paylater",
      }}
    >
      <PayPalButtons
        style={styles}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
      />
    </PayPalScriptProvider>
  );
}

export default PayPalButton;
