import React, { useState } from 'react';
import { Modal, TextInput, Textarea, Button, Group } from '@mantine/core';
import axios from 'axios';
import { getLocalStorage, setLocalStorage } from '../../Helper/LocalStorage';
import toast from 'react-hot-toast';

const EditBioModal = () => {
    const store = getLocalStorage('user')
  const [opened, setOpened] = useState(false);
  const [bio, setBio] = useState(''); // Initialize with the current bio of the user

  const handleUpdateBio = () => {
    // Logic to update the user's bio goes here (e.g., API call)
    if(bio === '') {toast.error("Bio cannot be empty"); return;}
    axios.patch(`${process.env.REACT_APP_BE_URI}/api/v1/${store.type === "professional" ? "prouser" :"stuuser"}/updatebio`, {
        userEmail: store.email,
        bio: bio
    }).then((res) => {
        if (res.status === 200) {
          toast.success("Bio Updated");
          setLocalStorage("user", { ...store, bio: res.data.bio });
          setOpened(false); // Close the modal after updating
          window.location.reload();
        }
    }).catch((err) => {
        toast.error(err.response.data.message);
        console.log(err);
    })
  };

  return (
    <>
      <Button onClick={() => setOpened(true)}>Edit Bio</Button>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Edit your Bio"
        size={"lg"}
          styles={{
            modal: {
              zIndex: 2000, // Increase the z-index to ensure it's above everything else
            },
          }}
        centered
      >
        <Textarea
          label="Bio"
          placeholder="Tell us about yourself"
          minRows={4}
          value={bio}
          autosize
          onChange={(event) => setBio(event.currentTarget.value)}
        />

        <Group position="right" mt="md">
          <Button onClick={() => setOpened(false)} variant="default">
            Cancel
          </Button>
          <Button onClick={handleUpdateBio}>Update Bio</Button>
        </Group>
      </Modal>
    </>
  );
};

export default EditBioModal;
