import React, { useEffect, useState } from "react";
import moment from "moment";

import styles from "./PaymentPage.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Group, Text } from "@mantine/core";
import PayPalButton from "../../components/PayPalButton/PayPalButton";
import { IconBrandStripe, IconCircleCheck } from "@tabler/icons-react";
const PaymentPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const { email, name, subsPrice, type } = location.state || {};
    const today = new Date();
    const nextYear = moment(today).add(1, "year").format("MMMM D, YYYY");
    const handlePayment = async () => {
        setLoading(true);
        try {
            //it creates the customer id of the new user or with new email eith their tpe prof or stu
            await axios.post(`${process.env.REACT_APP_BE_URI}/createcustomer`, {
                email,
                name,
                type,
            });
            //now after the creation of the customer id, it creates the checkout with the subs price and product
            const checkout = await axios.post(
                `${process.env.REACT_APP_BE_URI}/createcheckout`,
                {
                    email,
                    subsPrice,
                    type,
                }
            );
            //after the checkout is created, it redirects to the payment page
            if (checkout.data.url) {
                window.open(checkout.data.url, "_self");
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (!email || !type || !subsPrice || !name) {
            navigate("/");
        }
    }, [email, type, subsPrice, name, navigate]);
    return (
        <div className={styles.payment_page}>
            <IconCircleCheck size={50} color="green" />
            <Text
                variant="gradient"
                gradient={{ from: "cyan", to: "green", deg: 90 }}
                fw={700}
                size="xl"
                tt={"capitalize"}
            >
                Congratulations {name} !
            </Text>
            <Text
                variant="gradient"
                gradient={{ from: "cyan", to: "green", deg: 90 }}
                fw={500}
                size="lg"
            >
                Your application has been approved.
            </Text>
            <p>
                You are now ready to initiate your subscription payment. Please
                review the details below before proceeding.
            </p>
            <div className={styles.subscription_details}>
                <Text
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    size="lg"
                    fw={700}
                    td="underline"
                >
                    Subscription Information
                </Text>
                <ul>
                    <li>
                        <span>Type:</span>{" "}
                        {type === "professional" ? "Professional" : "Student"}
                    </li>
                    <li>
                        <span>Amount:</span> ${subsPrice}
                    </li>
                    <li>
                        <span>Recurring:</span> Yearly (next payment due{" "}
                        {nextYear})
                    </li>
                </ul>
            </div>
            <Group position="center">
                <Button
                    onClick={handlePayment}
                    loading={loading}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    size="md"
                    fullWidth
                    leftSection={<IconBrandStripe size={18} />}
                >
                    Pay with Stripe
                </Button>
            </Group>
            {/* paypal component with required props */}
            <div style={{ marginTop: "20px" }}>
                {(
                    <PayPalButton
                        type={
                            type === "professional" ? "Professional" : "Student"
                        }
                        amount={subsPrice}
                    ></PayPalButton>
                ) || <h1>loading paypal....</h1>}
            </div>
        </div>
    );
};

export default PaymentPage;
