import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Container,
    TextInput,
    PasswordInput,
    Button,
    Paper,
    Title,
    Text,
    Box,
    Group,
    Image,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const ResetPasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const { type, token } = useParams();
    // Media query hook for responsiveness
    const isMobile = useMediaQuery("(max-width: 728px)");

    // Formik form handling with Yup validation
    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, "Password must be at least 8 characters long")
                .required("Required"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password")], "Passwords must match")
                .required("Required"),
        }),
        onSubmit: () => {
            postResetPassword();
        },
    });
    const postResetPassword = async () => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_BE_URI}/api/v1/${
                    type === "pro" ? "prouser" : "stuuser"
                }/resetpassword/${token}`,
                {
                    password: formik.values.password,
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message);
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 2500);
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                console.log(err);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Container size="sm" style={{ padding: isMobile ? "20px" : "40px" }}>
            <Image w={100} mx="auto" mb={20} src="/pines_logo.png" />
            <Paper
                shadow="xl"
                radius="md"
                p={isMobile ? "lg" : "xl"}
                withBorder
                style={{
                    maxWidth: isMobile ? "100%" : "500px",
                    margin: "auto",
                    backgroundColor: "#f8f9fa",
                }}
            >
                <Title
                    align="center"
                    order={isMobile ? 2 : 1}
                    style={{ marginBottom: "1rem" }}
                >
                    Reset Your Password
                </Title>

                <Text align="center" c="dimmed" size="sm" mb={30}>
                    Enter your new password below to reset your account password
                </Text>

                <form onSubmit={formik.handleSubmit}>
                    <PasswordInput
                        label="New Password"
                        placeholder="New password"
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.password && formik.errors.password
                        }
                        required
                        mb="md"
                    />

                    <PasswordInput
                        label="Confirm Password"
                        placeholder="Confirm password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                        }
                        required
                        mb="md"
                    />

                    <Group position="center" mt="xl">
                        <Button
                            loading={loading}
                            variant="gradient"
                            gradient={{ from: "cyan", to: "green", deg: 90 }}
                            type="submit"
                            fullWidth
                            size="md"
                        >
                            Reset Password
                        </Button>
                    </Group>
                </form>
            </Paper>
        </Container>
    );
};

export default ResetPasswordPage;
