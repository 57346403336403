import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Container,
    TextInput,
    Button,
    Paper,
    Title,
    Text,
    Box,
    Group,
    Radio,
    Notification,
    Image,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import axios from "axios";
import toast from "react-hot-toast";

const ForgotPasswordPage = () => {
    const [loading, setLoading] = useState(false);
    // State to handle message after form submission
    const [resetLinkSent, setResetLinkSent] = useState(false);

    // Media query for responsiveness
    const isMobile = useMediaQuery("(max-width: 768px)");

    // Formik form handling with Yup validation
    const formik = useFormik({
        initialValues: {
            email: "",
            subscriptionType: "", // default value
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
            subscriptionType: Yup.string().required(
                "Please select your subscription type"
            ),
        }),
        onSubmit: () => {
            postForgotPassword();
        },
    });

    const postForgotPassword = () => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_BE_URI}/api/v1/${
                    formik.values.subscriptionType === "pro"
                        ? "prouser"
                        : "stuuser"
                }/forgotpassword`,
                {
                    email: formik.values.email,
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setResetLinkSent(true);
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                console.log(err);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Container size="sm" style={{ padding: isMobile ? "20px" : "40px" }}>
            <Image w={100} mx="auto" mb={20} src="/pines_logo.png" />
            <Paper
                shadow="xl"
                radius="md"
                p={isMobile ? "lg" : "xl"}
                withBorder
                style={{
                    maxWidth: isMobile ? "100%" : "500px",
                    margin: "auto",
                    backgroundColor: "#f8f9fa",
                }}
            >
                {!resetLinkSent ? (
                    <>
                        <Title
                            align="center"
                            order={isMobile ? 2 : 1}
                            style={{ marginBottom: "1rem" }}
                        >
                            Forgot Your Password?
                        </Title>

                        <Text align="center" c="dimmed" size="sm" mb={30}>
                            Enter your email address and select your
                            subscription type below to receive a password reset
                            link.
                        </Text>

                        <form onSubmit={formik.handleSubmit}>
                            <TextInput
                                label="Email Address"
                                placeholder="Your email"
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.email && formik.errors.email
                                }
                                required
                                mb="md"
                            />

                            {/* Radio button for Subscription Type */}
                            <Radio.Group
                                label="Subscription Type"
                                id="subscriptionType"
                                name="subscriptionType"
                                value={formik.values.subscriptionType}
                                onChange={(value) =>
                                    formik.setFieldValue(
                                        "subscriptionType",
                                        value
                                    )
                                }
                                error={
                                    formik.touched.subscriptionType &&
                                    formik.errors.subscriptionType
                                }
                                mb="md"
                            >
                                <Radio
                                    color="cyan"
                                    my={"sm"}
                                    value="stu"
                                    label="Student"
                                />
                                <Radio
                                    color="cyan"
                                    my={"sm"}
                                    value="pro"
                                    label="Professional"
                                />
                            </Radio.Group>

                            <Group position="center" mt="xl">
                                <Button
                                    variant="gradient"
                                    gradient={{
                                        from: "cyan",
                                        to: "green",
                                        deg: 90,
                                    }}
                                    loading={loading}
                                    type="submit"
                                    fullWidth
                                    size="md"
                                >
                                    Send Reset Link
                                </Button>
                            </Group>
                        </form>
                    </>
                ) : (
                    <Box>
                        <Notification
                            title="Success"
                            color="teal"
                            radius="md"
                            onClose={() => setResetLinkSent(false)}
                            withCloseButton={false}
                        >
                            Password reset link has been sent to your email.
                        </Notification>
                        <Text align="center" mt="lg" color="dimmed">
                            Please check your inbox and follow the instructions
                            to reset your password.
                        </Text>
                    </Box>
                )}
            </Paper>
        </Container>
    );
};

export default ForgotPasswordPage;
