import React, { useRef, useState } from "react";
import {
  Container,
  Title,
  TextInput,
  Button,
  Stack,
  FileInput,
  Select,
} from "@mantine/core";
import { getSubscriptionPrice } from "../Signup/subsPrice";
import { countries, courses } from "../Signup/variables";
import toast from "react-hot-toast";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../Helper/LocalStorage";
import axios from "axios";
import RenewModal from "./renewModal";

export const StudentValidation = () => {
  const childRef = useRef(null);
  const store = getLocalStorage("user");
  const [formData, setFormData] = useState({
    firstName: store.firstName,
    lastName: store.lastName,
    email: "",
    firstName: "",
    lastName: "",
    course: "",
    country: store.country,
    organization: "",
    graduationYear: "",
    subsPrice: 0,
    documents: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (files) => {
    setFormData({ ...formData, documents: files });
  };

  const handleChildModal = () => {
    if (childRef.current) {
      childRef.current.callChildModal();
    }
  };
  const handleSubmit = async () => {
    // Validate form data
    if (
      !formData.course ||
      !formData.organization ||
      !formData.graduationYear ||
      !formData.documents
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (formData.documents.type !== "application/pdf") {
      toast.error("Only PDF documents are allowed.");
      return;
    }
    // Handle form submission logic here
    const subsPrice = getSubscriptionPrice("stu", formData.country);
    formData.subsPrice = subsPrice;
    formData.email = store.email;
    formData.firstName = store.firstName;
    formData.lastName = store.lastName;
    axios
      .post(
        `${process.env.REACT_APP_BE_URI}/api/v1/stuuser/stutosturenewal`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setLocalStorage("user", {
            ...store,
            renew: true,
          });
          if (childRef.current) {
            childRef.current.callChildModal();
          }
        }
      })
      .catch((err) => {
        toast.error(
          "Error while processing your application. Error " + err.message
        );
      });
  };

  return (
    <Container size="sm" my="xl">
      <Title align="center" mb="xl">
        Student Validation
      </Title>
      <Stack spacing="md">
        <Select
          label="Course"
          name="course"
          data={courses}
          value={formData.course}
          onChange={(value) => setFormData({ ...formData, course: value })}
          required
        />
        <TextInput
          label="Country"
          name="country"
          data={countries}
          value={store.country}
          onChange={(value) => setFormData({ ...formData, country: value })}
          required
          disabled
        />
        <TextInput
          label="Organization"
          name="organization"
          value={formData.organization}
          onChange={handleInputChange}
          required
        />
        <Select
          label="Graduation Year"
          name="graduationYear"
          placeholder="Select year"
          data={Array(15)
            .fill(0)
            .map((_, index) => (new Date().getFullYear() + index).toString())}
          value={formData.graduationYear}
          onChange={(value) =>
            setFormData({ ...formData, graduationYear: value })
          }
          required
        />
        <FileInput
          label="Upload Documents"
          name="documents"
          placeholder="Upload professional ID or related documents"
          onChange={handleFileChange}
          required
        />
        <Button
          variant="gradient"
          gradient={{ from: "cyan", to: "green", deg: 90 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
      <RenewModal ref={childRef} />
    </Container>
  );
};
