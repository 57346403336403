import { Text, Box, Accordion } from '@mantine/core';
import { IconArrowBadgeRightFilled } from '@tabler/icons-react'
import classes from './Faqs.module.css';
import { useEffect, useState } from 'react';
import { useMantineTheme } from '@mantine/core';

const Directory = () => {
  const [newUser, setNewUser] = useState(true);
  const theme = useMantineTheme();

  const qna = [
    {
      key: 1,
      question: 'I have been a PINES member for many years, why is my ‘join date’ still listed as 2019?',
      answer:
        'The join date is pre-set by the membership database at the date which the account was added to the system. Since you were an existing member at the time the old website and database were transferred to the new one the join date reflects the date membership information was transferred.',
    },
    {
      key: 2,
      question: 'I am logged in why can’t I see the member’s only content?',
      answer:
        `Please check if your membership is still active. If your membership has expired your access to the website is restricted to the public areas and your member profile area. Once renewed you will be able to continue accessing member content such as feature articles, resources, conference reports and professional opportunities./n/n
        If this does not solve the access issue, email the admin at info@PINESnutrition.org. In the email, please answer the following diagnostic questions which will help with further troubleshooting the access problem, especially if we need to forward it to our developer for investigation./n/n
        Name:/n/n
        Email:/n/n
        What is the problem?/n/n
        Is the problem occurring with access from mobile, laptop or both?/n/n
        Which web browser are you using?/n/n
        Does the problem occur when accessing the website from different web browsers? E.G. Chrome, Explorer, Safari etc/n/n
        If relevant:/n/n
        Provide the error code or message (if one appears)./n/n
        Provide screenshot/s to illustrate the issue problem.`
    },
    {
      key: 3,
      question: 'How do I check when my membership renewal is due?',
      answer:
        'You can check when your membership renewal is due by going to the subscriptions section, here.',
    },
    {
      key: 4,
      question: 'What is auto-renewal?',
      answer:
        `Members who have joined since January 2019 or renewed since May 2019 have the benefit of their membership auto-renewing.  This feature saves you time and ensures you will have continuous access to the PINES e-news and the member only content./n/n
        Prior to your membership renewal date, you will be sent an automated reminder that your membership will process on your renewal date.  If you are a student member you may be asked to provide evidence of your ongoing enrolment in a relevant course/program or asked to provide evidence that you have finished and will be transferred to a professional membership./n/n
        Auto-renewal was added as part of the website/membership management database update in early 2019.  For members with renewals in June and July 2020, we appreciate your patience with this process; this will be the first bulk processing the new system will handle. We do not expect any issues, however, if you do experience a renewal problem, have a question, or would like to request a change to your payment please contact info@PINESnutrition.org in advance of your renewal date so we have time to process member communications.`,
    },
    {
      key: 5,
      question: 'Can I opt out of the auto-renewals and pay my membership another way?',
      answer:
        `If you do not want auto-renewal, your account can be switched to payments via invoice. Invoices would be sent annually on the day your membership expires. You will need to pay for these by the invoice due date to continue your membership. Please contact info@PINESnutrition.org to request the change to your renewal payment method.
        Changes to the payment method may take up to 2-3 business days so please provide at least three business days’ notice before your renewal date for the change to be processed. This will avoid your card being automatically charged.`,
    },
    {
      key: 6,
      question: 'My payment keeps failing.',
      answer:
        `A small number of people experience credit card processing problems. It is possible that some banks or credit card companies are blocking the transaction due to it appearing to be an unauthorized charge from a foreign country.
        If you think that your bank or credit card company is blocking the transaction or you are experiencing a payment issue, please contact the info@PINESnutrition.org address for an alternative payment option.`,
    },
    {
      key: 7,
      question: 'I accidentally renewed manually, with my auto-renewal still active.',
      answer:
        `Please select from one of two options and send an email to info@PINESnutrition.org with the required information.
        Option one: Update your membership subscription so you have 24 months instead of 12 months until the next renewal is due.
        Option two: PINES can provide a refund back to your card. Please note that refunds may take 5-10 days to appear on your card’s statement.
        Name:
        Email:
        Transaction dates:
        Option:`,
    },
    {
      key: 8,
      question: 'How do I change my membership type from student to professional or vice versa?',
      answer:
        `To process a change in membership type you will need to email info@PINESnutrition.org with the appropriate evidence. Once your membership has been changed you will need to upload at least one piece of evidence within your member’s area profile.
        Changing from student to professional membership
        Evidence of completed appropriate qualifications or professional accreditation.
        Example: Certificate of registration for your professional title (registered dietitian (RD), accredited practicing dietitian (APD) etc), certificate of degree or completed coursework, or other similar evidence.
        Changing from professional to student membership
        Evidence of current enrolment in a relevant course/program. A relevant course/program includes PhD, Master’s or Diploma course (e.g. IOC Diploma). Ensure your evidence shows your name, the education provider’s name and a recent date (or the commencement or expected completion date).
        Example: An enrolment letter, screenshot of student dashboard or academic transcript.
        Please note:
        Changes to the membership type may take up to 2-3 business days. Changing your membership type will change the amount due at your next renewal. Please provide at least three business days’ notice to allow enough time to process the change before your renewal date. This will avoid you being over charged.`,
    },
    {
      key: 9,
      question: 'How do I update my credit card information?',
      answer:
        'If your payment information has changed you can update your card details via the customer billing portal here.',
    },
    {
      key: 10,
      question: 'My membership expired. How do I renew it?',
      answer:
        `Our members are busy professionals, so we recently added an auto-renewal feature to assist our members with keeping their membership up-to-date. Members who were due to renew in December 2019 were not yet part of the new auto-renewal system. If that is you, please follow a manual renewal through the website. Once you follow this manual renewal all following annual renewals with be automated with reminders (and chance to opt-out of the renewal) emailed prior to payment being processed.
        Please follow the instructions in this brief video to show you how to do the manual renewal.
        00:00 | 01:35`,
    },
    {
      key: 11,
      question: 'How do I cancel my membership?',
      answer:
        `You can cancel your membership subscription through the payment customer billing portal. We recommend completing your cancellation at least two days prior to your renewal date just in case you have any trouble doing this you will then have time to contact the PINES admin and avoid your card being charged.`,
    },
    {
      key: 12,
      question: 'Can I get a membership certificate?',
      answer:
        `Yes, an online certificate of membership can be provided for current financial members.  Please complete the short form below and your certificate will be emailed to the nominated email address within a week.`,
    },
  ];

  const faqs = qna.map((item) => (
    <Accordion.Item key={item.key} value={item.question}>
      <Accordion.Control icon={<IconArrowBadgeRightFilled /> }>{item.question}</Accordion.Control>
      <Accordion.Panel>{item.answer.split("/n/n").map((para) => { return <p>{para}</p> })}</Accordion.Panel>
    </Accordion.Item>
  ));

  useEffect(() => {
    return () => {
      if (false) {
        setNewUser(false);
      }
      document.title = "FAQs";
    }
  }, []);

  return (
    <Box p={"40px"}>
      <Text
        fz={50}
        fw={700}
        variant="gradient"
        gradient={{ from: theme.colors.secondary[0], to: theme.colors.primary[0], deg: 95 }}
        display={"inline"}
      >
        FAQs
      </Text>
      <br />
      <Accordion classNames={classes}>
        {faqs}
      </Accordion>
    </Box>
  );
}
export default Directory;