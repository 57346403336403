import React from 'react';
import { Container, Text, Title, Button, Grid, Card, Image } from '@mantine/core';

function SampleHome() {
  return (
    <Container size="lg" py="xl">
      <Grid gutter="xl" justify="center">
        <Grid.Col xs={12} sm={6} lg={4}>
          <Card shadow="sm" radius="md" p="lg">
            <Card.Section>
              <Image src="pines-logo.png" alt="Pines Logo" width={120} />
            </Card.Section>
            <Card.Section>
              <Title order={2} align="center">
                Welcome to Pines!
              </Title>
              <Text size="lg" align="center" py="md">
                You're now part of our exclusive community!
              </Text>
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} lg={4}>
          <Card shadow="sm" radius="md" p="lg">
            <Card.Section>
              <Title order={3} align="center">
                Explore Our Features
              </Title>
              <Text size="lg" align="center" py="md">
                Discover the benefits of being a Pines member
              </Text>
              <Button variant="filled" color="blue" size="lg" py="md">
                Learn More
              </Button>
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} lg={4}>
          <Card shadow="sm" radius="md" p="lg">
            <Card.Section>
              <Title order={3} align="center">
                Get Started
              </Title>
              <Text size="lg" align="center" py="md">
                Begin your journey with Pines today!
              </Text>
              <Button variant="filled" color="green" size="lg" py="md">
                Start Exploring
              </Button>
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export default SampleHome;