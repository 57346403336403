import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box, // Import Box for spacing
} from "@mui/material";
import { PieChart } from "@mui/x-charts"; // Assuming @mui/x-charts is correctly installed
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import axios from "axios";
import { Link } from "react-router-dom";
// import "react-pro-sidebar/dist/css/styles.css"; // Import sidebar styles
// Dummy data
const professionalVsStudentData = [
  { name: "Professional Members", value: 1000 },
  { name: "Student Members", value: 500 },
];

const membersByCountryData = [
  { name: "USA", value: 600 },
  { name: "Canada", value: 300 },
  { name: "UK", value: 400 },
  { name: "Australia", value: 200 },
];

const pendingMembers = [
  { email: "john.doe@example.com" },
  { email: "jane.smith@example.com" },
  { email: "emily.johnson@example.com" },
];

const NewDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [totalProMembers, setTotalProMembers] = useState(0);
  const [totalStudentMembers, setTotalStudentMembers] = useState(0);
  // const [totalMembers, setTotalMembers] = useState(0);
  const [totalPendingApprovalPro, setTotalPendingApprovalPro] = useState([]);
  const [totalPendingApprovalStu, setTotalPendingApprovalStu] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  console.log("pending approval pro: ", totalPendingApprovalPro);
  const fetchData = async () => {
    //fetching the total number of professional members
    await axios
      .post(`${process.env.REACT_APP_BE_URI}/api/v1/admin/totalpromembers`, {
        adminEmail: "icsol@mail.com",
      })
      .then((response) => {
        setTotalProMembers(response.data.totalProMembers);
        console.log(response.data.totalProMembers);
      })
      .catch((error) => {
        console.log(error);
      });
    //fetching the total number of professionals pending for approval
    await axios
      .post(`${process.env.REACT_APP_BE_URI}/api/v1/admin/getpendingapprovalpro`, {
        adminEmail: "icsol@mail.com",
      })
      .then((response) => {
        setTotalPendingApprovalPro(response.data.pendingApprovalPro);
        console.log(response.data.pendingApprovalPro);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Box sx={{ display: "flex" }}>
          {/* Sidebar */}
          <Sidebar
            style={{ position: "fixed", height: "100%" }}
            // Allow sidebar to collapse
            collapsed={false}
            breakPoint="sm" // Responsive breakpoint
          >
            <Menu>
              <SubMenu label="Charts">
                <MenuItem> Pie charts </MenuItem>
                <MenuItem> Line charts </MenuItem>
              </SubMenu>
              <MenuItem> Documentation </MenuItem>
              <MenuItem> Calendar </MenuItem>
            </Menu>
          </Sidebar>

          {/* Main Content */}
          <Container maxWidth="lg" sx={{ ml: "240px", mt: 4 }}>
            {" "}
            {/* ml: '240px' ensures content is pushed right */}
            {/* Statistics Cards */}
            <Grid container spacing={4}>
              {/* Total Active Members */}
              <Grid item xs={12} sm={3}>
                <Card sx={{ height: "100%", p: 2 }}>
                  <Typography variant="h6">Total Active Member</Typography>
                  <Typography variant="h4">{totalProMembers + totalStudentMembers}</Typography>
                </Card>
              </Grid>

              {/* Pending Professionals */}
              <Grid item xs={12} sm={3}>
                <Card sx={{ height: "100%", p: 2 }}>
                  <Typography variant="h6">Pending Professionals</Typography>
                  <Typography variant="h4">{totalPendingApprovalPro.length}</Typography>
                </Card>
              </Grid>

              {/* Pending Students */}
              <Grid item xs={12} sm={3}>
                <Card sx={{ height: "100%", p: 2 }}>
                  <Typography variant="h6">Pending Students</Typography>
                  <Typography variant="h4">{totalPendingApprovalStu.length}</Typography>
                </Card>
              </Grid>

              {/* Total Revenue Generated */}
              <Grid item xs={12} sm={3}>
                <Card sx={{ height: "100%", p: 2 }}>
                  <Typography variant="h6">Total Revenue Generated</Typography>
                  <Typography variant="h4">$100,000</Typography>
                </Card>
              </Grid>
            </Grid>
            {/* Charts */}
            <Grid container spacing={3} sx={{ mt: 4 }}>
              {/* Professional vs Student Members */}
              <Grid item xs={12} md={6}>
                <Card sx={{ height: "100%", p: 2 }}>
                  <Typography variant="h6">
                    Professional vs Student Members
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: [
                          { id: 0, value: 10, label: "series A" },
                          { id: 1, value: 15, label: "series B" },
                          { id: 2, value: 20, label: "series C" },
                        ],
                      },
                    ]}
                    width={400}
                    height={200}
                  />
                  {/* <PieChart
            series={professionalVsStudentData}
            width={400}
            height={300}
            labelKey="name"
            valueKey="value"
          /> */}
                </Card>
              </Grid>

              {/* Members by Country */}
              <Grid item xs={12} md={6}>
                <Card sx={{ height: "100%", p: 2 }}>
                  <Typography variant="h6">Members by Country</Typography>
                  <PieChart
                    series={[
                      {
                        data: [
                          { id: 0, value: 10, label: "series A" },
                          { id: 1, value: 15, label: "series B" },
                          { id: 2, value: 20, label: "series C" },
                        ],
                      },
                    ]}
                    width={400}
                    height={200}
                  />
                  {/* <PieChart
            data={membersByCountryData}
            width={400}
            height={300}
            labelKey="name"
            valueKey="value"
          /> */}
                </Card>
              </Grid>
            </Grid>
            {/* Pending Approvals Table */}
            <Grid container spacing={3} sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <Card>
                  <Typography variant="h6" sx={{ p: 2 }}>
                    Pending Approvals
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {totalPendingApprovalPro.map((member, index) => (
                          <TableRow key={index}>
                            <TableCell>{member}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="success"
                                sx={{ mx: 1 }}
                              >
                                Approve
                              </Button>
                              <Link to={`/userdetail/${member}`}>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ mx: 1 }}
                                // onClick={()=> <Redirect href="/userdetail"/>}
                              >
                                View
                              </Button>
                              </Link>
                              <Button
                                variant="contained"
                                color="error"
                                sx={{ mx: 1 }}
                              >
                                Reject
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default NewDashboard;
