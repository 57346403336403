import React from 'react';
import { createContext, useState } from 'react';

export const apiContext = createContext(process.env.REACT_APP_BE_URI);

export const ApiContextWrapper = ({children}) =>{
    const [url, setUrl] = useState(process.env.REACT_APP_BE_URI);

    // const toggleUrl = ()=>{
    //     if(url =="http://localhost:5000"){
    //         setUrl("production.link");
    //     }
    //     else {
    //         setUrl("http://localhost:5000");
    //     }
    //     console.log(url)
    // }

    return(
        <apiContext.Provider value={{ url }}>
            {children}
        </apiContext.Provider>
    )
}

// export const ApiToggle = ({ label, ...otherProps }) => {
//   return (
//     <FormControlLabel
//       control={<Switch {...otherProps}/>}
//       label={
//         <span style={{ display: 'flex', alignItems: 'center' }}>
//             <ElectricBoltIcon sx={{color: "#FF0"}} />
//         </span>
//       }
//       sx={{position: "absolute", right: "10px"}}
//     />
//   );
// };
