import { Center, Loader, Text, Stack } from "@mantine/core";

export default function LoaderAnimation() {
  return (
    <Center style={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
      <Stack align="center" spacing="sm">
        <Loader size="lg" color="green" />
        <Text c="gray" size="lg">
          Loading...
        </Text>
      </Stack>
    </Center>
  );
}
