import React, { useState, useContext, useRef } from "react";
import { getLocalStorage, setLocalStorage } from "../../Helper/LocalStorage";
import { apiContext } from "../../utils/ApiContext";
import axios from "axios";
import { getCookie } from "../../Helper/Cokkies";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    CardSection,
    Center,
    Container,
    Group,
    Image,
    Paper,
    ScrollArea,
    Select,
    Skeleton,
    Stack,
    Switch,
    Tabs,
    Text,
    TextInput,
    Textarea,
    ThemeIcon,
    Title,
    useMantineTheme,
} from "@mantine/core";
import { IconAt, IconEdit, IconPhoneCall } from "@tabler/icons-react";
import classes from "./Profile.module.css";
import toast from "react-hot-toast";
import moment from "moment";
import EditBioModal from "./updateBio";
import { base64ToBlob } from "../../Helper/Base64ToBlob";
import { blobToBase64 } from "../../Helper/BlobToBase64";
import ChangePassword from "./changePassword";
import AdditionalInfo from "./updateAdditionalInfo";
const Profile = () => {
    const fileInputRef = useRef(null);
    const [optedIn, setOptedIn] = useState(false);
    const store = getLocalStorage("user");
    const profilePic = getLocalStorage("profilePic");
    const age = new Date(store.createdAt);
    const api = useContext(apiContext);
    const token = getCookie("token");
    const [isLoading, setIsLoading] = useState(false);
    const [fetchDocLoading, setFetchDocLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isAlert, setIsAlert] = useState({
        status: false,
        severity: "success",
        message: <></>,
    });
    const [disableButton, setDisableButton] = useState(true);
    const [profileImg, setProfileImg] = useState(null);
    const theme = useMantineTheme();

    React.useEffect(() => {
        document.title = "Profile";
        setOptedIn(store.directory);
    }, []);

    const getProfilePic = async (dataString) => {
        if (!store.profile) return;
        axios
            .get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getprofilepic/${
                    dataString || store.profile
                }`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    const img = URL.createObjectURL(res.data);
                    blobToBase64(res.data)
                        .then((base64String) => {
                            setLocalStorage("profilePic", base64String);
                            setProfileImg(img);
                        })
                        .catch((err) => {
                            toast.error(
                                "Failed to load profile picture, error at blobToBase64"
                            );
                            console.log(err);
                        });
                    console.log(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response?.data?.message);
            });
    };

    // Add logic to submit changes to your backend API (if applicable)
    const handleDirectoryUpdate = () => {
        axios
            .patch(
                `${process.env.REACT_APP_BE_URI}/api/v1/${
                    store.type === "professional" ? "prouser" : "stuuser"
                }/updatedirectory`,
                {
                    userEmail: store.email,
                    directory: optedIn,
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Directory Updated");
                    setLocalStorage("user", { ...store, directory: optedIn });
                }
            })
            .catch((err) => {
                setIsAlert({
                    status: true,
                    severity: "error",
                    message: <Text>{err.response?.data?.message}</Text>,
                });
            });
    };

    const handleButtonClick = () => {
        fileInputRef.current.click(); // Trigger the hidden file input
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("userEmail", store.email);
            formData.append("profile", file); // Assuming your server expects the file field to be named 'image'
            setIsLoading(true);
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_BE_URI}/api/v1/${
                        store.type === "professional" ? "prouser" : "stuuser"
                    }/updateprofilepic`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                setLocalStorage("user", {
                    ...store,
                    profile: response.data.profile,
                }); //updates the path of image in local storage
                getProfilePic(response.data.profile); //updates the image in local storage also. by passing the path of the image, it then fetches the image from the server and then sets it in local storage
                toast.success("Image uploaded successfully");
                // window.location.reload();
            } catch (error) {
                console.error(error);
                toast.error(
                    error.response?.data?.message?.split(": ")[1] ||
                        "Image File too large upload less than 1 MB"
                );
            }
            setIsLoading(false);
        }
    };

    const handleDocumentDownload = async () => {
        setFetchDocLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getFile/${store?.documents}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    const blob = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    const url = URL.createObjectURL(blob);
                    setPdfUrl(url);
                }
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    const errorMessage = err.response.data.message;
                    if (errorMessage.toLowerCase().includes("not found")) {
                        toast.error("No document found");
                    } else {
                        toast.error(errorMessage);
                    }
                } else {
                    toast.error("An unexpected error occurred");
                }
                console.log(err);
            })
            .finally(() => {
                setFetchDocLoading(false);
            });
    };

    return (
        <Box m="20px" p="20px" bg={"white"}>
            <Tabs
                color={theme.colors.primary[0]}
                defaultValue="Bio"
                classNames={{ panel: classes.panel }}
            >
                <Tabs.List>
                    <Tabs.Tab fz={18} value="Bio">
                        Bio
                    </Tabs.Tab>
                    <Tabs.Tab
                        onClick={handleDocumentDownload}
                        fz={18}
                        value="Documents"
                    >
                        Documents
                    </Tabs.Tab>
                    <Tabs.Tab fz={18} value="Security">
                        Security
                    </Tabs.Tab>
                    <Tabs.Tab fz={18} value="Directory">
                        Directory
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Bio">
                    <Group>
                        <div style={{ position: "relative" }}>
                            <Avatar
                                src={profileImg || profilePic}
                                size={110}
                                radius="lg"
                            />
                            <Button
                                loading={isLoading}
                                onClick={handleButtonClick}
                                className={classes.editProfile}
                            >
                                <IconEdit size={20} lh={10} />
                            </Button>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                accept="image/*" // Optional: restrict to image files only
                            />
                        </div>
                        <div className={classes.bio}>
                            <Text
                                style={{ textTransform: "capitalize" }}
                                fz={26}
                                fw={500}
                            >
                                {store?.firstName + " " + store?.lastName}{" "}
                                <span style={{ fontSize: "15px" }}>
                                    {store.type === "professional"
                                        ? "(Profesional)"
                                        : "(Student)"}
                                </span>
                            </Text>
                            <Text
                                style={{ textTransform: "capitalize" }}
                                fz="md"
                                fw={500}
                                lh={"120%"}
                                c="dimmed"
                            >
                                {store.type === "professional"
                                    ? store.profession
                                    : store.course}
                                , {store.country}
                            </Text>
                            <Text fz="md" fw={500} pt={10}>
                                Member Since:{" "}
                                {moment(store.createdAt).format("MMMM Do YYYY")}
                            </Text>
                        </div>
                    </Group>
                    <Group grow>
                        <TextInput
                            name="email"
                            label="Email"
                            placeholder="Email"
                            value={store?.email}
                            pt="md"
                            key="email"
                            disabled
                        />
                        <TextInput
                            name="country"
                            label="Country"
                            placeholder="Country"
                            value={store.country}
                            pt="md"
                            key="country"
                            disabled
                        />
                    </Group>
                    <Card shadow="sm" padding="lg" mt="md">
                        <Title order={3} mb="md" fz={"sm"}>
                            Biography
                        </Title>
                        <ScrollArea style={{ height: 200 }}>
                            <Text>{store.bio || "update your bio!!"}</Text>
                        </ScrollArea>
                    </Card>
                    <div style={{ marginTop: "10px" }}>
                        <EditBioModal />
                    </div>
                    <AdditionalInfo />
                </Tabs.Panel>

                <Tabs.Panel value="Documents">
                    {fetchDocLoading ? (
                        <Skeleton height={"80vh"} w={"100%"} />
                    ) : (
                        <iframe
                            src={pdfUrl}
                            style={{ width: "100%", height: "80vh" }}
                            title="PDF Viewer"
                        />
                    )}
                </Tabs.Panel>

                <Tabs.Panel value="Security">
                    <Center mx={"auto"} mt={20}>
                        <ChangePassword />
                    </Center>
                </Tabs.Panel>

                <Tabs.Panel value="Directory">
                    <Card
                        withBorder
                        shadow="sm"
                        radius="md"
                        p="lg"
                        style={{
                            maxWidth: 400,
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        <Title order={2} mb="sm">
                            Members Directory
                        </Title>
                        <Text size="md" mb="md">
                            Opt in to our members directory to share your basic
                            information like name, email, and profession with
                            other members.
                        </Text>
                        <Text size="sm" mb="lg">
                            This directory will be visible to other members and
                            will include details such as: name, email, and
                            profession (for professionals) and college and
                            course (for students).
                        </Text>
                        <Group position="center" mx={"auto"} mt="lg">
                            <Switch
                                checked={optedIn}
                                onChange={(e) => {
                                    setOptedIn(e.currentTarget.checked);
                                    setDisableButton(!disableButton);
                                }}
                                size="lg"
                                color="teal"
                                mt=""
                            />
                            <Badge
                                my={"auto"}
                                color="teal"
                                radius="md"
                                size="md"
                            >
                                {optedIn
                                    ? "You are opted in"
                                    : "You are opted out"}
                            </Badge>
                        </Group>
                        <Button
                            variant="gradient"
                            gradient={{ from: "cyan", to: "green", deg: 90 }}
                            radius="md"
                            size="md"
                            mt={10}
                            disabled={disableButton}
                            onClick={handleDirectoryUpdate}
                        >
                            Update
                        </Button>
                    </Card>
                </Tabs.Panel>
            </Tabs>
        </Box>
    );
};

export default Profile;
