import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Text, Alert } from "@mantine/core";
import { forwardRef, useImperativeHandle } from "react";
import {
  IconArrowRight,
  IconMail,
  IconRosetteDiscountCheck,
} from "@tabler/icons-react";

const RenewModal = forwardRef((props, ref) => {
  const [opened, { open, close }] = useDisclosure(false);
  //this is the child function that is exposed to the parent
  const callChildModal = () => {
    open();
  };
  //this function exposes the child function to the parent component
  useImperativeHandle(ref, () => ({
    callChildModal,
  }));

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          window.location.href = "/dashboard";
        }}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 4,
        }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Modal content */}
          <IconRosetteDiscountCheck color="#0ca678" size={100} />
          <Text
            size="xl"
            fw={900}
            variant="gradient"
            gradient={{ from: "cyan", to: "green", deg: 90 }}
          >
            Thanks for renewing your subscription, your profile has been sent
            for verification.
          </Text>
          <Alert variant="light" color="teal" icon={<IconMail size={20} />}>
            <Text
              size="md"
              fw={500}
              variant="gradient"
              gradient={{ from: "cyan", to: "green", deg: 90 }}
            >
              Please check your mail in a few days for confirmation
            </Text>
          </Alert>
          <Button
            onClick={() => {
              window.location.href = "/dashboard";
            }}
            mt={8}
            variant="gradient"
            gradient={{ from: "cyan", to: "green", deg: 90 }}
            rightSection={<IconArrowRight size={18} />}
          >
            Proceed
          </Button>
        </div>
      </Modal>
    </>
  );
});
export default RenewModal;
