import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    PasswordInput,
    Button,
    Box,
    Group,
    Paper,
    Title,
    Text,
} from "@mantine/core";
import axios from "axios";
import { getLocalStorage } from "../../Helper/LocalStorage";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Validation schema using Yup
const PasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("New password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
});

const ChangePassword = () => {
    const navigate = useNavigate();
    // useFormik hook for managing the form
    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: PasswordSchema,
        onSubmit: (values) => {
            axios
                .post(
                    `${
                        process.env.REACT_APP_BE_URI
                    }/api/v1/common/changepassword/${
                        getLocalStorage("user").type
                    }`,
                    {
                        email: getLocalStorage("user").email,
                        newPassword: values.newPassword,
                    }
                )
                .then((res) => {
                    if (res.status === 200) {
                        toast.success("Password changed successfully");
                        setTimeout(() => {
                            navigate("/dashboard");
                        }, 2500);
                        formik.resetForm();
                    }
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                    console.log(err);
                });
        },
    });

    return (
        <Box
            sx={(theme) => ({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor:
                    theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.colors.gray[0],
            })}
        >
            <Paper
                shadow="md"
                radius="md"
                p="xl"
                withBorder
                style={{ maxWidth: 400, width: "100%" }}
            >
                <Title order={2} align="center" mb="xl">
                    Change Password
                </Title>

                <form onSubmit={formik.handleSubmit}>
                    <PasswordInput
                        label="New Password"
                        placeholder="Enter your new password"
                        required
                        name="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.newPassword &&
                            formik.errors.newPassword
                        }
                        mb="md"
                    />

                    <PasswordInput
                        label="Confirm New Password"
                        placeholder="Confirm your new password"
                        required
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                        }
                        mb="md"
                    />

                    <Group position="center" mt="xl">
                        <Button
                            variant="gradient"
                            gradient={{
                                from: "cyan",
                                to: "green",
                                deg: 90,
                            }}
                            type="submit"
                            fullWidth
                        >
                            Change Password
                        </Button>
                    </Group>

                    <Text align="center" size="sm" mt="md" c="dimmed">
                        Make sure your password is strong and unique.
                    </Text>
                </form>
            </Paper>
        </Box>
    );
};

export default ChangePassword;
