// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./login-splash.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_form__P0\\+T4 {
    background-color: white;
    padding: 60px 50px;
    gap: 20px;
    justify-content: center;
    box-shadow: 0px 0px 10px 2px black;
}

.LoginPage_form__P0\\+T4 a {
    color: var(--mantine-color-blue-4);
    text-decoration: none;
}
.LoginPage_button__b\\+OTR {
    background: linear-gradient(
        135deg,
        var(--mantine-color-primary-0),
        var(--mantine-color-secondary-0)
    );
    width: 120pt;
    border-radius: 8pt;
    margin: 0px auto;
}

@media screen and (min-width: 480px) {
    .LoginPage_form_login__-EcVx {
        box-shadow: 10px 0px 10px -5px rgba(0, 0, 0, 0.3); /* Only right-side shadow */
        padding: 5% 5% 0 0;
        margin: 0 5% 0 5%;
        width: 50%;
        height: 100%;
    }
    
    .LoginPage_wrapper__gjfoB {
        display: flex;
        height: 100vh;
        width: 100vw;
    }
    .LoginPage_bg__FOYKc {
        width: 100%;
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
        background-size: cover;
    }
}
@media screen and (max-width: 480px) {
    .LoginPage_form_login__-EcVx {
        padding: 20% 5% 0 5%;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .LoginPage_wrapper__gjfoB {
        height: 100vh;
        width: 100vw;
        background-color: white;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Login/LoginPage.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,uBAAuB;IACvB,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;IAClC,qBAAqB;AACzB;AACA;IACI;;;;KAIC;IACD,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI;QACI,iDAAiD,EAAE,2BAA2B;QAC9E,kBAAkB;QAClB,iBAAiB;QACjB,UAAU;QACV,YAAY;IAChB;;IAEA;QACI,aAAa;QACb,aAAa;QACb,YAAY;IAChB;IACA;QACI,WAAW;QACX,yDAA2C;QAC3C,sBAAsB;IAC1B;AACJ;AACA;IACI;QACI,oBAAoB;QACpB,WAAW;QACX,YAAY;QACZ,gBAAgB;IACpB;IACA;QACI,aAAa;QACb,YAAY;QACZ,uBAAuB;IAC3B;AACJ","sourcesContent":[".form {\n    background-color: white;\n    padding: 60px 50px;\n    gap: 20px;\n    justify-content: center;\n    box-shadow: 0px 0px 10px 2px black;\n}\n\n.form a {\n    color: var(--mantine-color-blue-4);\n    text-decoration: none;\n}\n.button {\n    background: linear-gradient(\n        135deg,\n        var(--mantine-color-primary-0),\n        var(--mantine-color-secondary-0)\n    );\n    width: 120pt;\n    border-radius: 8pt;\n    margin: 0px auto;\n}\n\n@media screen and (min-width: 480px) {\n    .form_login {\n        box-shadow: 10px 0px 10px -5px rgba(0, 0, 0, 0.3); /* Only right-side shadow */\n        padding: 5% 5% 0 0;\n        margin: 0 5% 0 5%;\n        width: 50%;\n        height: 100%;\n    }\n    \n    .wrapper {\n        display: flex;\n        height: 100vh;\n        width: 100vw;\n    }\n    .bg {\n        width: 100%;\n        background-image: url(\"./login-splash.jpg\");\n        background-size: cover;\n    }\n}\n@media screen and (max-width: 480px) {\n    .form_login {\n        padding: 20% 5% 0 5%;\n        width: 100%;\n        height: 100%;\n        overflow: hidden;\n    }\n    .wrapper {\n        height: 100vh;\n        width: 100vw;\n        background-color: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `LoginPage_form__P0+T4`,
	"button": `LoginPage_button__b+OTR`,
	"form_login": `LoginPage_form_login__-EcVx`,
	"wrapper": `LoginPage_wrapper__gjfoB`,
	"bg": `LoginPage_bg__FOYKc`
};
export default ___CSS_LOADER_EXPORT___;
