// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Faqs_root__bpB9c {
    border-radius: var(--mantine-radius-md);
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
}

.Faqs_control__WKUje,.Faqs_control__WKUje:hover{
    background-color: var(--mantine-color-green-3);
    border-radius: var(--mantine-radius-md);
    font-weight: bolder;
}

.Faqs_item__9pqSs {
    background-color: var(--mantine-color-green-3);
    border-bottom: 1px solid #00800040;
    position: relative;
    z-index: 0;
    transition: transform 150ms ease;
}

.Faqs_item__9pqSs[data-active] {
    transform: scale(1.03);
    z-index: 1;
    box-shadow: var(--mantine-shadow-md);
    border-radius: var(--mantine-radius-md);
    box-shadow: 0px 5px 20px 1px #00000050;
}

.Faqs_panel__QqQnU{
    background-color: var(--mantine-color-blue-2);
    border-radius: 0 0 var(--mantine-radius-md) var(--mantine-radius-md);
}

.Faqs_chevron__fzhCB[data-rotate] {
    transform: rotate(-180deg);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Faqs/Faqs.module.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,sFAAsF;AAC1F;;AAEA;IACI,8CAA8C;IAC9C,uCAAuC;IACvC,mBAAmB;AACvB;;AAEA;IACI,8CAA8C;IAC9C,kCAAkC;IAClC,kBAAkB;IAClB,UAAU;IACV,gCAAgC;AACpC;;AAEA;IACI,sBAAsB;IACtB,UAAU;IACV,oCAAoC;IACpC,uCAAuC;IACvC,sCAAsC;AAC1C;;AAEA;IACI,6CAA6C;IAC7C,oEAAoE;AACxE;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".root {\n    border-radius: var(--mantine-radius-md);\n    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));\n}\n\n.control,.control:hover{\n    background-color: var(--mantine-color-green-3);\n    border-radius: var(--mantine-radius-md);\n    font-weight: bolder;\n}\n\n.item {\n    background-color: var(--mantine-color-green-3);\n    border-bottom: 1px solid #00800040;\n    position: relative;\n    z-index: 0;\n    transition: transform 150ms ease;\n}\n\n.item[data-active] {\n    transform: scale(1.03);\n    z-index: 1;\n    box-shadow: var(--mantine-shadow-md);\n    border-radius: var(--mantine-radius-md);\n    box-shadow: 0px 5px 20px 1px #00000050;\n}\n\n.panel{\n    background-color: var(--mantine-color-blue-2);\n    border-radius: 0 0 var(--mantine-radius-md) var(--mantine-radius-md);\n}\n\n.chevron[data-rotate] {\n    transform: rotate(-180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Faqs_root__bpB9c`,
	"control": `Faqs_control__WKUje`,
	"item": `Faqs_item__9pqSs`,
	"panel": `Faqs_panel__QqQnU`,
	"chevron": `Faqs_chevron__fzhCB`
};
export default ___CSS_LOADER_EXPORT___;
