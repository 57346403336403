import * as React from "react";
import { useState } from "react";
import {
    Box,
    Button,
    Group,
    Image,
    Paper,
    Stack,
    Text,
    useMantineTheme,
} from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./Signup.module.css";
import student from "./student.png";
import professional from "./professional.png";

function Copyright(props) {
    return (
        <Text fz="xs" c="dimmed" align="center" {...props}>
            {"© All right reserved 2024. "}
            <Link c="red" target={"_"} to="https://pinesnutrition.org/">
                PINES Nutrition
            </Link>
        </Text>
    );
}

export default function Signup() {
    const [memberType, setMemberType] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const theme = useMantineTheme();

    React.useEffect(() => {
        document.title = "Sign Up";
    }, []);

    const step = () => {
        if (memberType) {
            setLoading(true);
            if (memberType === "student") window.location = "/student-signup";
            else if (memberType === "professional")
                window.location = "/professional-signup";
        } else {
            setError(true);
        }
    };

    return (
        <>
            <Box className={classes.box}>
                <Stack className={classes.form}>
                    <Text
                        fz={{ base: 24, md: 36 }}
                        fw={800}
                        variant="gradient"
                        gradient={{
                            from: theme.colors.secondary[0],
                            to: theme.colors.primary[0],
                            deg: 135,
                        }}
                        display={"inline"}
                        mx="auto"
                        align="center"
                    >
                        Sign Up
                    </Text>
                    <Text
                        fz={{ base: 22, md: 28 }}
                        fw={600}
                        mx="auto"
                        align="center"
                    >
                        What defines you best?
                    </Text>
                    <Group className={classes.type_group}>
                        <Paper
                            data-selected={memberType === "student"}
                            className={classes.tile}
                            onMouseOver={() => setMemberType("student")}
                        >
                            <Image
                                src={student}
                                w={150}
                                p="xs"
                                mx="auto"
                            ></Image>
                            <Text fz={20} fw={600} mx="auto" align="center">
                                Student
                            </Text>
                        </Paper>
                        <Paper
                            data-selected={memberType === "professional"}
                            className={classes.tile}
                            onMouseOver={() => setMemberType("professional")}
                        >
                            <Image
                                src={professional}
                                w={150}
                                p="xs"
                                mx="auto"
                            ></Image>
                            <Text fz={20} fw={600} mx="auto" align="center">
                                Professional
                            </Text>
                        </Paper>
                    </Group>
                    <Button
                        h={50}
                        w="80%"
                        fz={20}
                        radius={10}
                        variant="gradient"
                        gradient={{
                            from: theme.colors.secondary[0],
                            to: theme.colors.primary[0],
                            deg: -75,
                        }}
                        mx="auto"
                        loading={loading}
                        onClick={() => {
                            step();
                        }}
                    >
                        Submit
                    </Button>
                    {error && (
                        <Text c="red" align="center">
                            Please select a response
                        </Text>
                    )}
                    <Copyright
                        style={{ transform: "translateY(30px)" }}
                    ></Copyright>
                </Stack>
            </Box>
        </>
    );
}
