import React, { useEffect, useState } from "react";
import {
    Card,
    Text,
    Title,
    Stack,
    Container,
    Badge,
    Flex,
    Button,
} from "@mantine/core";
import { IconCalendarMonth } from "@tabler/icons-react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function OpportunitiesView() {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [loading, setLoading] = useState(true); // Add loading state
    const [content, setContent] = useState({
        title: "",
        category: "",
        images: "",
        description: "",
        postDate: "",
        formLink: "",
    });

    useEffect(() => {
        if (!id) return;
        getOppotunity();
    }, [id]);
    const getOppotunity = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/opportunity/${id}`
            );
            const data = response.data;
            console.log(data);
            let imgData = [];
            //'/api/v1/common/blogimages' is a common route to get the images from the server by passing the img name
            data?.images.map(async (item) => {
                const img = await axios.get(
                    `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${item}`,
                    {
                        responseType: "blob",
                    }
                );
                console.log("this is img: ", URL.createObjectURL(img.data));
                setContent((prev) => ({
                    ...prev,
                    image: [...prev.images, URL.createObjectURL(img.data)],
                }));
                imgData.push(URL.createObjectURL(img.data));
            });
            console.log(imgData);
            setContent({
                title: data.title,
                images: imgData, // Ensure this is returned by your API
                slideCaptions: data.slideCaptions,
                description: data.description,
                postDate: data.createdAt,
                formLink: data.formLink,
            });
            setLoading(false); // Set loading to false once data is fetched
        } catch (err) {
            console.log(err);
            toast.error(err.response.data.message || "Failed to load blog");
            setLoading(false); // Ensure loading is set to false even on error
        }
    };

    return (
        <Container size="lg" my="md">
             <Title ml={20} my={20}>
                Professional Opportunity
            </Title>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Stack spacing="md">
                    {/* IMAGE SLIDES SECTION START*/}
                    {content.images?.length > 0 ? (
                        <div style={{ width: "100%", marginTop: "10px" }}>
                            <Carousel showIndicators={false}>
                                {content.images?.map((item, index) => (
                                    <div key={index}>
                                        <img
                                            style={{ width: "100%" }}
                                            src={item}
                                        />
                                        <p>{content.slideCaptions[index]}</p>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    ) : null}
                    {/* IMAGE SLIDES SECTION END  */}

                    <Flex>
                        <Title
                            style={{ textTransform: "capitalize" }}
                            order={2}
                        >
                            {content.title}
                        </Title>
                        <Badge
                            size="lg"
                            my={"auto"}
                            ml={"auto"}
                            variant="gradient"
                            gradient={{ from: "blue", to: "green", deg: 90 }}
                            style={{ textTransform: "capitalize" }}
                            leftSection={<IconCalendarMonth size={15} />}
                        >
                            Posted At:
                            {moment(content.createdAt).format(
                                "dddd, MMMM Do, YYYY"
                            )}
                        </Badge>
                    </Flex>

                    <Text size="md">
                        <div
                            className="mantine-RichTextEditor-content"
                            dangerouslySetInnerHTML={{
                                __html: content.description,
                            }}
                        />
                    </Text>

                    <Link to={content.formLink} style={{ width: "100%", textDecoration: "none" }}>
                        <Button
                            variant="gradient"
                            gradient={{ from: "cyan", to: "lime", deg: 90 }}
                            fullWidth
                            mt="md"
                        >
                            Apply
                        </Button>
                    </Link>
                </Stack>
            </Card>
        </Container>
    );
}
