import { Container, Title, Text, Center } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

const Empty = ({heading, description}) => {

    return (
        <Container size="sm" style={{ marginTop: '50px' }}>
            <Center>
                <IconExclamationCircle size={50} color="gray" />
            </Center>
            <Title order={1} size="h3" align="center" mt="md">
                {heading}
            </Title>
            <Text align="center" c="dimmed" mt="md">
                {description}
            </Text>
        </Container>
    );
};

export default Empty;
