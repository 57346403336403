import React, { useState } from "react";
import { Container, Title, Button, Group, Stack, Text } from "@mantine/core";
import { StudentValidation } from "./studentValidation";
import toast from "react-hot-toast";
import { getLocalStorage } from "../../Helper/LocalStorage";
import UnderProcess from "./underProcess";
import { ProfessionalValidation } from "./professionalValidation";
import AutomaticRenewAlert from "./automaticRenewAlert";

export const Renewal = ({ type, endDate }) => {
    const [isStudent, setIsStudent] = useState(null);

    const handleStudentSelection = (status) => {
        setIsStudent(status);
    };

    if (type === "professional") {
        return <AutomaticRenewAlert endDate={endDate} />;
    }
    if (isStudent === null) {
        return (
            <Container size="sm" my="xl">
                <Title align="center" mb="xl">
                    Are you still a student?
                </Title>
                <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                    <Button
                        size="sm"
                        fullWidth
                        mx={5}
                        variant="gradient"
                        gradient={{ from: "cyan", to: "green", deg: 90 }}
                        onClick={() => handleStudentSelection(true)}
                    >
                        Yes
                    </Button>
                    <Button
                        size="sm"
                        fullWidth
                        mx={5}
                        variant="outline"
                        gradient={{ from: "cyan", to: "green", deg: 90 }}
                        onClick={() => setIsStudent(false)}
                    >
                        No
                    </Button>
                </div>
            </Container>
        );
    }

    if (isStudent) {
        return <StudentValidation />;
    }
    if (!isStudent) {
        return <ProfessionalValidation />;
    }
    // Redirect or navigate to another page if not a student
    window.location.href = "/another-page"; // Replace with your actual route
    return null;
};
