import * as React from "react";
import axios from "axios";
import { useState, useContext } from "react";
import { apiContext } from "../../utils/ApiContext";
import {
    Badge,
    Box,
    Button,
    Card,
    FileInput,
    Flex,
    Group,
    Image,
    List,
    Modal,
    PasswordInput,
    Select,
    Stack,
    Switch,
    Text,
    TextInput,
    Title,
    useMantineTheme,
} from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./StudentSignUp.module.css";
import { IconArrowRight, IconReload } from "@tabler/icons-react";
import logo from "./logo.png";
import { countries, courses } from "./variables";
import { getSubscriptionPrice } from "./subsPrice";
import { useDisclosure } from "@mantine/hooks";
import toast from "react-hot-toast";

function Copyright(props) {
    return (
        <Text fz="xs" c="dimmed" align="center" {...props}>
            {"© All right reserved 2024. "}
            <Link c="red" target={"_"} href="https://pinesnutrition.org/">
                Pines Nutrition
            </Link>
        </Text>
    );
}

export default function StudentSignUp() {
    const [opened, { open, close }] = useDisclosure(false);
    const [optedIn, setOptedIn] = useState(false);
    const [DataForm, setDataForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        cpassword: "",
        country: "",
        profession: "",
        qualification: "",
        qualificationYear: "",
        documents: null,
        subsPrice: "",
        directory: optedIn,
    });
    const [isValid, setIsValid] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        cpassword: "",
        country: "",
        organization: "",
        course: "",
        qualificationYear: "",
        documents: "",
    });
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isRegistered, setIsRegistered] = useState({
        status: null,
        message: "",
    });
    const api = useContext(apiContext);
    const theme = useMantineTheme();

    React.useEffect(() => {
        document.title = "Student Sign-Up";
    }, []);

    const changeHandler = (e) => {
        const field = e.target.name;
        setIsValid({ ...isValid, [field]: "" });
        setDataForm({ ...DataForm, [field]: e.target.value });
    };

    const handleDDinput = (v, key) => {
        setDataForm({ ...DataForm, [key]: v });
        if (v === null) setIsValid({ ...isValid, [key]: "Required" });
        else setIsValid({ ...isValid, [key]: "" });
    };

    const documentHandler = (e) => {
        const file = e;
        if (file) {
            if (file && file.size > 15 * 1024 * 1024) {
                // 15 MB in bytes
                toast.error(
                    "File size is too large. Please upload a smaller file upto 15 MB."
                );
                return;
            }
            if (file.type !== "application/pdf") {
                toast.error(
                    "File type is not supported. Please upload a PDF file."
                );
                return;
            }
        }
        if (e === null) {
            //incase file was removed
            setIsValid({ ...isValid, documents: "Required" });
            return;
        }
        setDataForm({ ...DataForm, documents: e });
        setIsValid({ ...isValid, documents: "" });
    };

    const validationHandler = (e) => {
        const field = e.target;
        if (field.value === "" || field.value === null) {
            setIsValid({ ...isValid, [field.name]: "Required" });
            return;
        }
        switch (field.name) {
            case "firstName":
                setIsValid({
                    ...isValid,
                    [field.name]:
                        field.value.length < 2
                            ? "Should be atleast 2 characters"
                            : "",
                });
                break;
            case "lastName":
                setIsValid({
                    ...isValid,
                    [field.name]:
                        field.value.length < 2
                            ? "Should be atleast 2 characters"
                            : "",
                });
                break;
            case "email":
                setIsValid({
                    ...isValid,
                    [field.name]: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(field.value)
                        ? ""
                        : "Invalid email format",
                });
                break;
            case "password":
                setIsValid({
                    ...isValid,
                    [field.name]:
                        field.value.length < 8
                            ? "Must be atleast 8 characters"
                            : "",
                    cpassword:
                        DataForm.cpassword !== DataForm.password
                            ? "Passwords do not match"
                            : "",
                });
                break;
            case "cpassword":
                setIsValid({
                    ...isValid,
                    [field.name]:
                        field.value !== DataForm.password
                            ? "Passwords do not match"
                            : "",
                });
                break;
            case "organization":
                setIsValid({
                    ...isValid,
                    [field.name]:
                        field.value.length < 3
                            ? "Should be atleast 3 characters"
                            : "",
                });
                break;
            case "qualificationYear":
                setIsValid({
                    ...isValid,
                    [field.name]:
                        parseInt(field.value, 10) >= new Date().getFullYear() &&
                        parseInt(field.value, 10) <=
                            new Date().getFullYear() + 7
                            ? ""
                            : "Enter valid year",
                });
                break;
            default:
                break;
        }
    };

    const nextStep = () => {
        const invalidList = { ...isValid };

        const validateStepFields = (field, requiredLength, validationFn) => {
            // Updated signature for validation function
            if (DataForm[field] === "" || DataForm[field] === null) {
                invalidList[field] = "Required";
            } else if (
                requiredLength &&
                DataForm[field].length < requiredLength
            ) {
                invalidList[
                    field
                ] = `Should be atleast ${requiredLength} characters`;
            } else if (validationFn && !validationFn(DataForm[field])) {
                // Use validationFn if provided
                invalidList[field] = "Invalid Year";
            } else {
                invalidList[field] = "";
            }
        };

        switch (step) {
            case 0:
                validateStepFields("firstName", 2);
                validateStepFields("lastName", 2);
                validateStepFields("email", /^[^\s@]+@[^\s@]+\.[^\s@]+$/);
                validateStepFields("password", 8);
                validateStepFields(
                    "cpassword",
                    null,
                    DataForm.cpassword !== DataForm.password
                        ? "Passwords do not match"
                        : null
                ); // Optional validation for password match
                break;
            case 1:
                validateStepFields("country");
                validateStepFields("organization", 3);
                validateStepFields("course");
                const parsedYear = parseInt(DataForm.qualificationYear, 10);
                const currentYear = new Date().getFullYear();
                parsedYear >= currentYear - 50 && parsedYear <= currentYear + 7
                    ? (invalidList.qualificationYear = "")
                    : (invalidList.qualificationYear = "Enter valid year");

                if (DataForm.documents === null) {
                    invalidList.documents = "Required";
                } else if (
                    !DataForm.documents.name.match(/\.(jpeg|jpg|png|pdf)$/)
                ) {
                    invalidList.documents =
                        "Invalid document format. Only JPG, JPEG, PNG, and PDF allowed.";
                } else {
                    invalidList.documents = "";
                }
                break;
            default:
                break;
        }

        setIsValid({ ...isValid, ...invalidList });
        if (step === 0) {
            if (Object.values(invalidList).every((value) => value === "")) {
                setStep(step + 1);
            }
        }
        if (step === 1) {
            if (Object.values(invalidList).every((value) => value === "")) {
                setStep(step + 1);
            }
        }
        if (step === 2) {
            if (Object.values(invalidList).every((value) => value === "")) {
                return true;
            } else {
                setStep(step + 1);
                return false;
            }
        }
    };

    const handleSubmit = () => {
        if (!nextStep()) {
            return;
        }
        setLoading(true);
        const subsPrice = getSubscriptionPrice("stu", DataForm.country);
        DataForm.subsPrice = subsPrice;
        const formData = new FormData();
        Object.keys(DataForm).forEach((key) => {
            formData.append(key, DataForm[key]);
        });
        console.log("from stu sign up handleSubmit() form data: ", DataForm);
        axios
            .post(api.url + "/api/v1/stuuser/register", DataForm, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                setIsRegistered({
                    status: true,
                    message:
                        "You have successfully submitted your application, please wait while our team can review the same. We shall get back to you shortly.",
                });
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    setIsRegistered({
                        status: false,
                        message:
                            "User already registered with the given email ",
                    });
                    return;
                }
                setIsRegistered({
                    status: false,
                    message:
                        "Error while processing your application. Error " +
                        err.message,
                });
            })
            .finally(() => setLoading(false));

        setStep(step + 1);
    };

    return (
        <>
            {/* modal for showing required documents for students */}
            <Modal opened={opened} onClose={close} title="Required Documents">
                <List>
                    <List.Item>Recent Transcript</List.Item>
                    <List.Item>Acceptance or Entrance Letter</List.Item>
                </List>
                <Button
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    onClick={close}
                    mt={"md"}
                >
                    Ok
                </Button>
            </Modal>

            <Box className={classes.box}>
                <form className={classes.form}>
                    <Image src={logo} w={100} mx={"auto"} />
                    <Text
                        pt="md"
                        fz={{ base: 24, md: 38 }}
                        fw={800}
                        variant="gradient"
                        gradient={{
                            from: theme.colors.secondary[0],
                            to: theme.colors.primary[0],
                            deg: 135,
                        }}
                        display={"inline"}
                        mx="auto"
                        align="center"
                    >
                        Student Sign Up
                    </Text>
                    <Stack py={20} gap={10}>
                        {step === 0 && (
                            <>
                                <Group grow>
                                    <TextInput
                                        label="First Name"
                                        key="firstName"
                                        name="firstName"
                                        value={DataForm?.firstName}
                                        onChange={(e) => changeHandler(e)}
                                        onBlur={(e) => validationHandler(e)}
                                        error={isValid?.firstName}
                                        required
                                    ></TextInput>
                                    <TextInput
                                        label="Last Name"
                                        key="lastName"
                                        name="lastName"
                                        value={DataForm?.lastName}
                                        onChange={(e) => changeHandler(e)}
                                        onBlur={(e) => validationHandler(e)}
                                        error={isValid?.lastName}
                                        required
                                    ></TextInput>
                                </Group>
                                <TextInput
                                    label="Email"
                                    key="email"
                                    name="email"
                                    placeholder="xyz@domain.com"
                                    value={DataForm?.email}
                                    onChange={(e) => changeHandler(e)}
                                    onBlur={(e) => validationHandler(e)}
                                    error={isValid?.email}
                                    required
                                ></TextInput>
                                <Group grow>
                                    <PasswordInput
                                        label="Password"
                                        key="password"
                                        name="password"
                                        placeholder="Password"
                                        value={DataForm.password}
                                        onChange={(e) => changeHandler(e)}
                                        onBlur={(e) => validationHandler(e)}
                                        error={isValid.password}
                                        required
                                    ></PasswordInput>
                                    <PasswordInput
                                        label="Confirm Password"
                                        key="cpassword"
                                        name="cpassword"
                                        placeholder="Confirm Password"
                                        value={DataForm.cpassword}
                                        onChange={(e) => changeHandler(e)}
                                        onBlur={(e) => validationHandler(e)}
                                        error={isValid.cpassword}
                                        required
                                    ></PasswordInput>
                                </Group>
                                <Button
                                    onClick={nextStep}
                                    rightSection={<IconArrowRight />}
                                    className={classes.nextStep}
                                >
                                    Next
                                </Button>
                            </>
                        )}
                        {step === 1 && (
                            <>
                                <Select
                                    label="Country"
                                    key="country"
                                    data={countries}
                                    value={DataForm.country}
                                    onChange={(v) =>
                                        handleDDinput(v, "country")
                                    }
                                    onBlur={(e) => validationHandler(e)}
                                    error={isValid.country}
                                    searchable
                                    required
                                ></Select>
                                <TextInput
                                    label="Current Institution"
                                    key="organization"
                                    name="organization"
                                    value={DataForm.organization}
                                    onChange={(e) => changeHandler(e)}
                                    onBlur={(e) => validationHandler(e)}
                                    error={isValid.organization}
                                    required
                                ></TextInput>
                                <Group>
                                    <Select
                                        label="Current Course"
                                        key="course"
                                        data={courses}
                                        value={DataForm.course}
                                        onChange={(v) =>
                                            handleDDinput(v, "course")
                                        }
                                        onBlur={(e) => validationHandler(e)}
                                        error={isValid.course}
                                        required
                                    ></Select>
                                    <TextInput
                                        label="Course Completion Year"
                                        key="qualificationYear"
                                        name="qualificationYear"
                                        placeholder="YYYY"
                                        value={DataForm.qualificationYear}
                                        onChange={(e) => changeHandler(e)}
                                        onBlur={(e) => validationHandler(e)}
                                        error={isValid.qualificationYear}
                                        required
                                    ></TextInput>
                                </Group>
                                <Flex style={{ position: "relative" }}>
                                    <FileInput
                                        label="Documents"
                                        key="documents"
                                        name="documents"
                                        placeholder="Only PDF allowed"
                                        style={{
                                            width: "404px",
                                            overflow: "hidden",
                                        }}
                                        onChange={(e) => documentHandler(e)}
                                        error={isValid.documents}
                                        accept="application/pdf"
                                        clearable
                                        required
                                        w={"50%"}
                                    ></FileInput>
                                    <Button
                                        variant="gradient"
                                        gradient={{
                                            from: "cyan",
                                            to: "green",
                                            deg: 90,
                                        }}
                                        style={{
                                            position: "absolute",
                                            right: "0",
                                            bottom: "0",
                                        }}
                                        onClick={open}
                                    >
                                        View Documents
                                    </Button>
                                </Flex>
                                <Text size="sm" c={"red"}>
                                    **All the documents should be combined in
                                    one pdf & file size must be upto 15 MB only
                                </Text>
                                <Button
                                    onClick={handleSubmit}
                                    rightSection={<IconArrowRight />}
                                    className={classes.nextStep}
                                    loading={loading}
                                >
                                    Submit
                                </Button>
                            </>
                        )}
                        {step === 2 && (
                            <>
                                <Card
                                    withBorder
                                    shadow="sm"
                                    radius="md"
                                    p="lg"
                                    style={{
                                        maxWidth: 400,
                                        margin: "auto",
                                        textAlign: "center",
                                    }}
                                >
                                    <Title order={2} mb="sm">
                                        Members Directory
                                    </Title>
                                    <Text size="md" color="dimmed" mb="md">
                                        Opt in to our members directory to share
                                        your basic information like name, email,
                                        and profession with other members.
                                    </Text>
                                    <Text size="sm" mb="lg">
                                        This directory will be visible to other
                                        members and will include details such
                                        as: name, email, and profession (for
                                        professionals) and college and course
                                        (for students).
                                    </Text>
                                    <Group
                                        position="center"
                                        mx={"auto"}
                                        mt="lg"
                                    >
                                        <Switch
                                            checked={DataForm.directory}
                                            onChange={(e) => {
                                                setOptedIn(
                                                    e.currentTarget.checked
                                                );
                                                setDataForm({
                                                    ...DataForm,
                                                    directory:
                                                        e.currentTarget.checked,
                                                });
                                            }}
                                            size="lg"
                                            color="teal"
                                            mt=""
                                        />
                                        <Badge
                                            my={"auto"}
                                            color="teal"
                                            radius="md"
                                            size="md"
                                        >
                                            {optedIn
                                                ? "You are opted in"
                                                : "You are opted out"}
                                        </Badge>
                                    </Group>
                                    <Button
                                        variant="gradient"
                                        gradient={{
                                            from: "cyan",
                                            to: "green",
                                            deg: 90,
                                        }}
                                        radius="md"
                                        size="md"
                                        mt={10}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Card>
                            </>
                        )}
                        {step === 3 && (
                            <>
                                {isRegistered.status ? (
                                    <>
                                        <Text
                                            c={theme.colors.green[3]}
                                            align="center"
                                        >
                                            {isRegistered.message}
                                        </Text>
                                        <Button
                                            href="https://pinesnutrition.org"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            loading={loading}
                                            variant="outline"
                                            rightSection={<IconArrowRight />}
                                        >
                                            Back to Pines
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Text
                                            c={theme.colors.red[3]}
                                            align="center"
                                        >
                                            {isRegistered.message}
                                        </Text>
                                        <Button
                                            onClick={() =>
                                                window.location.reload()
                                            }
                                            loading={loading}
                                            variant="outline"
                                            rightSection={<IconReload />}
                                        >
                                            Retry
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </Stack>
                    {Copyright()}
                </form>
                <div className={classes.bg} />
            </Box>
        </>
    );
}
