import { Alert, Button, Container, Text } from "@mantine/core";
import { IconScript } from "@tabler/icons-react";
import moment from "moment";
import React from "react";

export default function AutomaticRenewAlert({ endDate }) {
    const icon = <IconScript />;
    return (
        <Container mb="50vh" mt={50}>
            <Alert
                variant="light"
                color="green"
                title="Professional Subscription"
                icon={icon}
            >
                <Text
                    size="lg"
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    fw={"bold"}
                >
                    {" "}
                    Your respective proferssional subscription will be renewed
                    on or after {moment(endDate).format("MMMM Do YYYY")}. You
                    will recieve an autogenerated email from our side to renew
                    your subscription
                </Text>
                <Button
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    my={20}
                    onClick={() => (window.location.href = "/dashboard")}
                >
                    Home
                </Button>
            </Alert>
        </Container>
    );
}
