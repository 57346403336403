import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Text, Alert, Container } from "@mantine/core";
import { forwardRef, useImperativeHandle } from "react";
import {
  IconArrowRight,
  IconMail,
  IconProgress,
  IconProgressAlert,
  IconRosetteDiscountCheck,
} from "@tabler/icons-react";

const UnderProcess = forwardRef((props, ref) => {
  const [opened, { open, close }] = useDisclosure(false);
  //this is the child function that is exposed to the parent
  const callChildModal = () => {
    open();
  };
  //this function exposes the child function to the parent component
  useImperativeHandle(ref, () => ({
    callChildModal,
  }));

  return (
    <>
      <Container
        size="sm"
        // my="xl"
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {/* Modal content */}
        <IconProgressAlert color="#0ca678" size={100} />
        <Text
          size="xl"
          fw={900}
          variant="gradient"
          gradient={{ from: "cyan", to: "green", deg: 90 }}
        >
          Thanks for renewing your subscription, your profile is under process.
        </Text>
        <Alert variant="light" color="teal" mt={20} icon={<IconMail size={20} />}>
          <Text
            size="md"
            fw={500}
            variant="gradient"
            gradient={{ from: "cyan", to: "green", deg: 90 }}
          >
            Please check your mail in a few days for confirmation
          </Text>
        </Alert>
      </Container>
    </>
  );
});
export default UnderProcess;
