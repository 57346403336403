import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const UserDetailsPage = () => {
  // Sample user data (replace with your actual data or use state/props)
  const { userEmail } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_URI}/api/v1/admin/getprodetails`, {
        userEmail: userEmail,
      })
      .then((response) => {
        setUser(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const handleApprove = () => {
    // Handle approve logic here
    axios
      .post(`${process.env.REACT_APP_BE_URI}/api/v1/admin/approvepro`, {
        adminEmail: "icsol@mail.com",
        userEmail: userEmail,
      })
      .then(() => {
        toast.success("User approved successfully");
        navigate("/newdashboard");
      })
      .catch((error) => {
        toast.error("Error approving user details");
        console.log(error);
      });
  };

  const handleReject = () => {
    // Handle reject logic here
    console.log("User rejected");
  };

  return (
    <>
      {loading ? (
        <div>Loading....</div>
      ) : (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              User Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>First Name:</strong> {user?.firstName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Last Name:</strong> {user?.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Email:</strong> {user?.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Profile:</strong> {user?.profile}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Country:</strong> {user?.country}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Qualification:</strong> {user?.qualification}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Year of Qualification:</strong>{" "}
                  {user?.qualificationYear}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Profession:</strong> {user?.profession}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <strong>Documents:</strong> {user?.documents}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleApprove}
                  fullWidth
                >
                  Approve
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleReject}
                  fullWidth
                >
                  Reject
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Container>
      )}
    </>
  );
};

export default UserDetailsPage;
