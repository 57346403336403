import React, { useState } from "react";
import {
    Card,
    Text,
    Group,
    Grid,
    Container,
    Title,
    Button,
} from "@mantine/core";
import { IconRepeat } from "@tabler/icons-react";
import { getLocalStorage } from "../../Helper/LocalStorage";
import moment from "moment";
import { Renewal } from "./renewal";
import UnderProcess from "./underProcess";
// Default export
const SubscriptionDetails = () => {
    const [step, setStep] = useState(0);
    const subscription = {
        price: "49.99",
        startDate: "2024-01-01",
        endDate: "2024-12-31",
        type: "Premium",
    };
    const store = getLocalStorage("user");
    if (store?.renew) {
        return <UnderProcess />;
    }
    return (
        <>
            {step === 0 ? (
                <Container size="sm" mt="xl" mb={'50vh'}>
                    <Title align="center" mb="xl">
                        Subscription Details
                    </Title>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Grid>
                            <Grid.Col span={6}>
                                <Text size="lg" weight={500}>
                                    Subscription Type:
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text
                                    style={{ textTransform: "capitalize" }}
                                    size="lg"
                                >
                                    {store?.type}
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text size="lg" weight={500}>
                                    Start Date:
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text size="lg">
                                    {moment(store?.startDate).format(
                                        "MMMM Do YYYY"
                                    )}
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text size="lg" weight={500}>
                                    End Date:
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text size="lg">
                                    {moment(store?.endDate).format(
                                        "MMMM Do YYYY"
                                    )}
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text size="lg" weight={500}>
                                    Price:
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text size="lg">${store?.subsPrice}</Text>
                            </Grid.Col>
                        </Grid>
                        <Button
                            onClick={() => setStep(1)}
                            mt={20}
                            leftSection={<IconRepeat size={18} />}
                            variant="gradient"
                            gradient={{ from: "cyan", to: "green", deg: 90 }}
                        >
                            Renew Now
                        </Button>
                        {/* <button onClick={() => setStep(1)}>
              renewal
            </button> */}
                    </Card>
                </Container>
            ) : null}
            {step === 1 ? <Renewal type={store?.type} endDate={store?.endDate}/> : null}
        </>
    );
};

export default SubscriptionDetails;

// Import without curly braces
// import SubscriptionDetails from './path/to/your/file';
