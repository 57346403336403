// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_navbar__HSIOu {
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  height: 100%;
}

.Sidebar_navbarMain__cCF6v {
  flex: 1 1;
  overflow: auto;
}

.Sidebar_footer__lodno {
  border-top: 1px solid var(--mantine-color-gray-3);
}

.Sidebar_link__yiwQU {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-7);
  padding: var(--mantine-spacing-md) var(--mantine-spacing-lg);
  border-radius: var(--mantine-radius-sm);
  font-weight: 500;
}

.Sidebar_link__yiwQU[data-active] {
    background-color: var(--mantine-color-blue-light);
    /* color: var(--mantine-color-blue-light-color); */

}

.Sidebar_link__yiwQU[data-active]:hover {
    background-color: var(--mantine-color-blue-light);
    /* color: var(--mantine-color-blue-light-color); */
}

.Sidebar_link__yiwQU:hover {
  background-color: var(--mantine-color-gray-0);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.Sidebar_linkIcon__Py1pg {
  margin-right: var(--mantine-spacing-sm);
  border-radius: 5px;
  width: 25px;
  height: 25px;
}

.Sidebar_user__maVXc {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-md);
  color: var(--mantine-color-black);
}

.Sidebar_user__maVXc[data-active]{
  background-color: var(--mantine-color-gray-3);
}

.Sidebar_user__maVXc:hover{
  background-color: var(--mantine-color-gray-3);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Navbar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,SAAO;EACP,cAAc;AAChB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,sCAAsC;EACtC,kCAAkC;EAClC,4DAA4D;EAC5D,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;IACI,iDAAiD;IACjD,kDAAkD;;AAEtD;;AAEA;IACI,iDAAiD;IACjD,kDAAkD;AACtD;;AAEA;EACE,6CAA6C;EAC7C,yEAAyE;AAC3E;;AAEA;EACE,uCAAuC;EACvC,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kCAAkC;EAClC,iCAAiC;AACnC;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":[".navbar {\n  display: flex;\n  flex-direction: column;\n  justify-content:flex-end;\n  height: 100%;\n}\n\n.navbarMain {\n  flex: 1;\n  overflow: auto;\n}\n\n.footer {\n  border-top: 1px solid var(--mantine-color-gray-3);\n}\n\n.link {\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  font-size: var(--mantine-font-size-sm);\n  color: var(--mantine-color-gray-7);\n  padding: var(--mantine-spacing-md) var(--mantine-spacing-lg);\n  border-radius: var(--mantine-radius-sm);\n  font-weight: 500;\n}\n\n.link[data-active] {\n    background-color: var(--mantine-color-blue-light);\n    /* color: var(--mantine-color-blue-light-color); */\n\n}\n\n.link[data-active]:hover {\n    background-color: var(--mantine-color-blue-light);\n    /* color: var(--mantine-color-blue-light-color); */\n}\n\n.link:hover {\n  background-color: var(--mantine-color-gray-0);\n  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));\n}\n\n.linkIcon {\n  margin-right: var(--mantine-spacing-sm);\n  border-radius: 5px;\n  width: 25px;\n  height: 25px;\n}\n\n.user {\n  display: block;\n  width: 100%;\n  padding: var(--mantine-spacing-md);\n  color: var(--mantine-color-black);\n}\n\n.user[data-active]{\n  background-color: var(--mantine-color-gray-3);\n}\n\n.user:hover{\n  background-color: var(--mantine-color-gray-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `Sidebar_navbar__HSIOu`,
	"navbarMain": `Sidebar_navbarMain__cCF6v`,
	"footer": `Sidebar_footer__lodno`,
	"link": `Sidebar_link__yiwQU`,
	"linkIcon": `Sidebar_linkIcon__Py1pg`,
	"user": `Sidebar_user__maVXc`
};
export default ___CSS_LOADER_EXPORT___;
