import React, { useEffect, useState } from "react";
import {
    Container,
    Paper,
    Text,
    Button,
    Stack,
    ThemeIcon,
    Title,
    Box,
    Center,
} from "@mantine/core";
import { keyframes } from "@emotion/react";
import { IconX } from "@tabler/icons-react";

const bounce = keyframes({
    "from, 20%, 53%, 80%, to": { transform: "translate3d(0, 0, 0)" },
    "40%, 43%": { transform: "translate3d(0, -20px, 0)" },
    "70%": { transform: "translate3d(0, -15px, 0)" },
    "90%": { transform: "translate3d(0, -4px, 0)" },
});

const PaymentCancelPage = () => {
    return (
        <Container size="xs" h="100vh">
            <Center h="100%">
                <Paper
                    shadow="md"
                    p="xl"
                    radius="md"
                    withBorder
                    sx={{
                        maxWidth: 400,
                        width: "100%",
                    }}
                >
                    <Stack spacing="lg" align="center">
                        <Box
                            sx={{
                                animation: `${bounce} 2s ease infinite`,
                            }}
                        >
                            <ThemeIcon
                                size={80}
                                radius="xl"
                                variant="light"
                                c="red"
                                sx={(theme) => ({
                                    backgroundColor: theme.fn.rgba(
                                        theme.colors.red[6],
                                        0.1
                                    ),
                                })}
                            >
                                <IconX size={40} />
                            </ThemeIcon>
                        </Box>

                        <Stack spacing="xs" align="center">
                            <Title order={2} align="center">
                                Payment Cancelled
                            </Title>
                            <Text c="dimmed" size="md" align="center">
                                Your payment request has been cancelled. No
                                charges were made to your account.
                            </Text>
                        </Stack>

                        <Stack spacing="sm" w="100%">
                            <Button
                                component="a"
                                href="/login"
                                variant="light"
                                color="green"
                                fullWidth
                            >
                                Return to Login
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Center>
        </Container>
    );
};

export default PaymentCancelPage;
