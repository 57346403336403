// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Directory_wideFlex__o4a21 {
    display: flex;
    justify-content: space-between;
}

.Directory_contents__-agI1 {
    padding: 10px;
}

.Directory_member_card__k9Wp5,
.Directory_email_badge__iH6vE {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Directory/Directory.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB;;AAEA;;IAEI,eAAe;AACnB","sourcesContent":[".wideFlex {\n    display: flex;\n    justify-content: space-between;\n}\n\n.contents {\n    padding: 10px;\n}\n\n.member_card,\n.email_badge {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wideFlex": `Directory_wideFlex__o4a21`,
	"contents": `Directory_contents__-agI1`,
	"member_card": `Directory_member_card__k9Wp5`,
	"email_badge": `Directory_email_badge__iH6vE`
};
export default ___CSS_LOADER_EXPORT___;
