const countries = [
    "Aruba",
    "Afghanistan",
    "Angola",
    "Albania",
    "Andorra",
    "United Arab Emirates",
    "Argentina",
    "Armenia",
    "American Samoa",
    "Antigua and Barbuda",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Burundi",
    "Belgium",
    "Benin",
    "Burkina Faso",
    "Bangladesh",
    "Bulgaria",
    "Bahrain",
    "Bahamas, The",
    "Bosnia and Herzegovina",
    "Belarus",
    "Belize",
    "Bermuda",
    "Bolivia",
    "Brazil",
    "Barbados",
    "Brunei Darussalam",
    "Bhutan",
    "Botswana",
    "Central African Republic",
    "Canada",
    "Switzerland",
    "Channel Islands",
    "Chile",
    "China",
    "Côte d’Ivoire",
    "Cameroon",
    "Congo, Democratic Republic",
    "Congo, Republic",
    "Colombia",
    "Comoros",
    "Cabo Verde",
    "Costa Rica",
    "Cuba",
    "Curaçao",
    "Cayman Islands",
    "Cyprus",
    "Czechia",
    "Germany",
    "Djibouti",
    "Dominica",
    "Denmark",
    "Dominican Republic",
    "Algeria",
    "Ecuador",
    "Egypt, Arab Republic",
    "Eritrea",
    "Spain",
    "Estonia",
    "Ethiopia",
    "Finland",
    "Fiji",
    "France",
    "Faroe Islands",
    "Micronesia, Fed. Sts.",
    "Gabon",
    "United Kingdom",
    "Georgia",
    "Ghana",
    "Gibraltar",
    "Guinea",
    "Gambia, The",
    "Guinea-Bissau",
    "Equatorial Guinea",
    "Greece",
    "Grenada",
    "Greenland",
    "Guatemala",
    "Guam",
    "Guyana",
    "Hong Kong SAR, China",
    "Honduras",
    "Croatia",
    "Haiti",
    "Hungary",
    "Indonesia",
    "Isle of Man",
    "India",
    "Ireland",
    "Iran, Islamic Republic",
    "Iraq",
    "Iceland",
    "Israel",
    "Italy",
    "Jamaica",
    "Jordan",
    "Japan",
    "Kazakhstan",
    "Kenya",
    "Kyrgyz Republic",
    "Cambodia",
    "Kiribati",
    "St. Kitts and Nevis",
    "Korea, Republic",
    "Kuwait",
    "Lao PDR",
    "Lebanon",
    "Liberia",
    "Libya",
    "St. Lucia",
    "Liechtenstein",
    "Sri Lanka",
    "Lesotho",
    "Lithuania",
    "Luxembourg",
    "Latvia",
    "Macao SAR, China",
    "St. Martin (French part)",
    "Morocco",
    "Monaco",
    "Moldova",
    "Madagascar",
    "Maldives",
    "Mexico",
    "Marshall Islands",
    "North Macedonia",
    "Mali",
    "Malta",
    "Myanmar",
    "Montenegro",
    "Mongolia",
    "Northern Mariana Islands",
    "Mozambique",
    "Mauritania",
    "Mauritius",
    "Malawi",
    "Malaysia",
    "Namibia",
    "New Caledonia",
    "Niger",
    "Nigeria",
    "Nicaragua",
    "Netherlands",
    "Norway",
    "Nepal",
    "Nauru",
    "New Zealand",
    "Oman",
    "Pakistan",
    "Panama",
    "Peru",
    "Philippines",
    "Palau",
    "Papua New Guinea",
    "Poland",
    "Puerto Rico",
    "Korea, Democratic People's Republic",
    "Portugal",
    "Paraguay",
    "West Bank and Gaza",
    "French Polynesia",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saudi Arabia",
    "Sudan",
    "Senegal",
    "Singapore",
    "Solomon Islands",
    "Sierra Leone",
    "El Salvador",
    "San Marino",
    "Somalia",
    "Serbia",
    "South Sudan",
    "São Tomé and Príncipe",
    "Suriname",
    "Slovak Republic",
    "Slovenia",
    "Sweden",
    "Eswatini",
    "Sint Maarten (Dutch part)",
    "Seychelles",
    "Syrian Arab Republic",
    "Turks and Caicos Islands",
    "Chad",
    "Togo",
    "Thailand",
    "Tajikistan",
    "Turkmenistan",
    "Timor-Leste",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Türkiye",
    "Tuvalu",
    "Taiwan, China",
    "Tanzania",
    "Uganda",
    "Ukraine",
    "Uruguay",
    "United States",
    "Uzbekistan",
    "St. Vincent and the Grenadines",
    "Venezuela, RB",
    "British Virgin Islands",
    "Virgin Islands (U.S.)",
    "Vietnam",
    "Vanuatu",
    "Samoa",
    "Kosovo",
    "Yemen, Republic",
    "South Africa",
    "Zambia",
    "Zimbabwe",
]
const courses = ['Bachelors', 'Masters', 'PHD', 'IOC - Diploma', 'Others']
const professions = ['Dietician', 'Nutritionist', 'Doctor', 'Researcher', 'Professor', 'Others']

export { countries, courses, professions }