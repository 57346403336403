// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_line__mZcD5{
    width: 50px;
    margin: 0;
    margin-bottom: 20px;
}

.Dashboard_card__r5Ziw{
    display: flex;
}

.Dashboard_alternate__ryw95 .Dashboard_card__r5Ziw:nth-child("even"){
    flex-direction: row-reverse;
}

.Dashboard_jobcard__KyRMi{
    height: 100%;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".line{\n    width: 50px;\n    margin: 0;\n    margin-bottom: 20px;\n}\n\n.card{\n    display: flex;\n}\n\n.alternate .card:nth-child(\"even\"){\n    flex-direction: row-reverse;\n}\n\n.jobcard{\n    height: 100%;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `Dashboard_line__mZcD5`,
	"card": `Dashboard_card__r5Ziw`,
	"alternate": `Dashboard_alternate__ryw95`,
	"jobcard": `Dashboard_jobcard__KyRMi`
};
export default ___CSS_LOADER_EXPORT___;
