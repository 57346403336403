// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_nav__8osOA{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.Navbar_nav__8osOA .Navbar_controls__Z0xMy {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Navbar_nav__8osOA .Navbar_controls__Z0xMy .Navbar_searchbox__7YbPW{
  width: 300px;
}

.Navbar_link__n-9aV {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-gray-7);
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
}

.Navbar_link__n-9aV:hover {
  background-color: var(--mantine-color-blue-1);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Navbar/Navbar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,cAAc;EACd,iBAAiB;EACjB,uCAAuC;EACvC,qBAAqB;EACrB,kCAAkC;EAClC,sCAAsC;EACtC,gBAAgB;AAClB;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":[".nav{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n}\n\n.nav .controls {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.nav .controls .searchbox{\n  width: 300px;\n}\n\n.link {\n  display: block;\n  line-height: 1;\n  padding: 8px 12px;\n  border-radius: var(--mantine-radius-sm);\n  text-decoration: none;\n  color: var(--mantine-color-gray-7);\n  font-size: var(--mantine-font-size-sm);\n  font-weight: 500;\n}\n\n.link:hover {\n  background-color: var(--mantine-color-blue-1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `Navbar_nav__8osOA`,
	"controls": `Navbar_controls__Z0xMy`,
	"searchbox": `Navbar_searchbox__7YbPW`,
	"link": `Navbar_link__n-9aV`
};
export default ___CSS_LOADER_EXPORT___;
