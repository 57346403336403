import {
    Button,
    Card,
    Center,
    Flex,
    Group,
    Textarea,
    TextInput,
    Title,
    Tooltip,
} from "@mantine/core";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getLocalStorage, setLocalStorage } from "../../Helper/LocalStorage";
import { IconInfoCircle } from "@tabler/icons-react";

export default function AdditionalInfo() {
    const store = getLocalStorage("user");
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            phone: "",
            address: "",
            zipCode: "",
            city: "",
            website: "",
            pinesBenefits: "",
            hearPines: "",
            sportScience: "",
        },
        onSubmit: () => {
            handleUpdateAdditionalInfo();
        },
    });

    useEffect(() => {
        formik.setValues({
            phone: store.phone,
            address: store.address,
            zipCode: store.zipCode,
            city: store.city,
            website: store.website,
            pinesBenefits: store.pinesBenefits,
            hearPines: store.hearPines,
            sportScience: store.sportScience,
        });
    }, []);

    const handleUpdateAdditionalInfo = () => {
        setLoading(true);
        axios
            .patch(
                `${process.env.REACT_APP_BE_URI}/api/v1/${
                    store.type === "professional" ? "prouser" : "stuuser"
                }/addtionalinfo`,
                {
                    userEmail: store.email,
                    phone: formik.values.phone,
                    address: formik.values.address,
                    zipCode: formik.values.zipCode,
                    city: formik.values.city,
                    website: formik.values.website,
                    pinesBenefits: formik.values.pinesBenefits,
                    hearPines: formik.values.hearPines,
                    sportScience: formik.values.sportScience,
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Additional Information Updated");
                    setLocalStorage("user", {
                        ...store,
                        phone: res.data.phone,
                        address: res.data.address,
                        zipCode: res.data.zipCode,
                        city: res.data.city,
                        website: res.data.website,
                        pinesBenefits: res.data.pinesBenefits,
                        hearPines: res.data.hearPines,
                        sportScience: res.data.sportScience,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500);
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const tooltipContent =
        "Your personal details, such as your phone number, address, city, and zip code, will not be shared with other members, even if you opt in for the members' directory.";

    return (
        <>
            <Card shadow="sm" padding="lg" mt="md">
                <Flex align="center">
                    <Title order={3} mr={10} fz="sm" ta="left">
                        Additional Information (optional)
                    </Title>
                    <Tooltip label={tooltipContent}>
                        <IconInfoCircle size={18} />
                    </Tooltip>
                </Flex>

                <form onSubmit={formik.handleSubmit}>
                    <Group grow>
                        <TextInput
                            name="phone"
                            label="Phone"
                            placeholder="your contact number"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            pt="md"
                            key="phone"
                        />
                        <TextInput
                            name="address"
                            label="Address"
                            placeholder="your address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            pt="md"
                            key="address"
                        />
                    </Group>

                    <Group grow>
                        <TextInput
                            name="zipCode"
                            label="Zip Code"
                            placeholder="your zip code"
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                            pt="md"
                            key="zipCode"
                        />
                        <TextInput
                            name="city"
                            label="City"
                            placeholder="your city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            pt="md"
                            key="city"
                        />
                    </Group>

                    <Group grow>
                        <Textarea
                            name="pinesBenefits"
                            label="What is the greatest benefit PINES membership can offer you ?"
                            placeholder="your answer here"
                            value={formik.values.pinesBenefits}
                            onChange={formik.handleChange}
                            pt="md"
                            key="pinesBenefits"
                            resize="vertical"
                        />
                        <Textarea
                            name="hearPines"
                            label="How did you hear about Pines?"
                            placeholder="your answer here"
                            value={formik.values.hearPines}
                            onChange={formik.handleChange}
                            pt="md"
                            key="hearPines"
                            resize="vertical"
                        />
                    </Group>

                    <Textarea
                        name="sportScience"
                        label="Which sport science or sport nutrition organisations are you currently a member of ?"
                        placeholder="your answer here"
                        value={formik.values.sportScience}
                        onChange={formik.handleChange}
                        pt="md"
                        key="sportScience"
                        resize="vertical"
                    />
                    <TextInput
                        name="website"
                        label="Website (if any)"
                        placeholder="your website link here"
                        value={formik.values.website}
                        onChange={formik.handleChange}
                        pt="md"
                        key="website"
                    />
                    <Button loading={loading} type="submit" my={10}>
                        Save
                    </Button>
                </form>
            </Card>
        </>
    );
}
