import * as React from "react";
import { setLocalStorage, getLocalStorage } from "../../Helper/LocalStorage";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { apiContext } from "../../utils/ApiContext";
import {
    Button,
    Image,
    PasswordInput,
    Stack,
    Text,
    TextInput,
    SegmentedControl,
    useMantineTheme,
    Flex,
} from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./LoginPage.module.css";
import { removeCookie, setCookie } from "../../Helper/Cokkies";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { blobToBase64 } from "../../Helper/BlobToBase64";

// copyight component
function Copyright(props) {
    return (
        <>
            <Flex fz="sm" c="dimmed" mx={"auto"} align="center" {...props}>
                {"Don't have an account? "}
                <Text
                    c="blue"
                    fz={"sm"}
                    ml={5}
                    td="underline"
                    component="a"
                    href="/signup"
                >
                    Sign Up
                </Text>
            </Flex>
            <Text fz="xs" c="dimmed" align="center" {...props}>
                {"© All right reserved 2024. "}
                <Link c="blue" target={"_"} href="https://pinesnutrition.org/">
                    Pines Nutrition
                </Link>
            </Text>
        </>
    );
}

export default function LoginPage() {
    const navigate = useNavigate(); //to directly navigate to desired page with details using state
    const [DataForm, setDataForm] = useState({ email: "", password: "" }); //states for controlled components
    const [isInvalid, setIsInvalid] = useState({ email: false, password: "" }); //error messages for controlled components
    const [userType, setUserType] = useState("Professional");
    const [isLogedin, setIslogedin] = useState(
        getLocalStorage("user") ? true : false
    ); //check local storage for user object
    const [loading, setLoading] = useState(false); //button load state
    const api = useContext(apiContext); //context to access api url
    const theme = useMantineTheme(); //object holds theme colors

    //setting page title
    React.useEffect(() => {
        document.title = "Login";
    }, []);

    //if user object found in local storage/ user just logged in, redirect to dashboard
    if (isLogedin) {
        return <Navigate replace to="/dashboard" />;
    }
    //this is defined because I'm not getting the value for a particular cookie, so it will return the value of a particular cookie and then the cookie would be stored by above defined functions
    function getCookieValue(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    //form submission
    const handleSubmit = () => {
        setLoading(true);
        setIsInvalid({ ...isInvalid, email: "", password: "" }); //clear errors
        const endpoint =
            userType === "Professional"
                ? "/api/v1/prouser/login"
                : "/api/v1/stuuser/login";
        axios
            .post(api.url + endpoint, DataForm, {
                withCredentials: true,
            })
            .then(function (response) {
                setLoading(false);
                const currentDate = new Date();
                if (!response.data?.finalUser.approve) {
                    toast.error("Please wait for admin approval");
                } else if (response.data?.finalUser.reject) {
                    toast.error(
                        "Your account has been rejected by admin, please again after some time"
                    );
                } else if (!response.data?.finalUser.active) {
                    navigate("/subscriptionpayment", {
                        state: {
                            email: response.data.finalUser?.email,
                            name:
                                response.data.finalUser.firstName +
                                " " +
                                response.data.finalUser?.lastName,
                            subsPrice: response.data.finalUser.subsPrice,
                            type: response.data.finalUser.type,
                        },
                    });
                } else if (response.data?.finalUser.renewPayment) {
                    console.log(response.data?.finalUser.type);
                    navigate("/renewalpayment", {
                        state: {
                            email: response.data.finalUser?.email,
                            name:
                                response.data.finalUser.firstName +
                                " " +
                                response.data.finalUser?.lastName,
                            subsPrice: response.data.finalUser.subsPrice,
                            endDate: response.data.finalUser.endDate,
                            type: response.data.finalUser.type,
                        },
                    });
                } else if (
                    response.data.finalUser?.endDate < currentDate.toISOString()
                ) {
                    navigate("/renewalpayment", {
                        state: {
                            email: response.data.finalUser?.email,
                            name:
                                response.data.finalUser.firstName +
                                " " +
                                response.data.finalUser?.lastName,
                            subsPrice: response.data.finalUser.subsPrice,
                            endDate: response.data.finalUser.endDate,
                            type: response.data.finalUser.type,
                        },
                    });
                } else {
                    //getCookieValue funciton will parse the cookie and return the value of a particular cookie
                    // setCookie("token", getCookieValue("accessToken"));
                    setLocalStorage("user", response.data.finalUser); //load fetched user details into localstorage
                    getProfilePic(response.data.finalUser?.profile);
                    setIslogedin(true); //set user as logged in & then redirect to dashboard
                }
            })
            .catch(function (error) {
                try {
                    if (error.response.data.message.includes("password")) {
                        //if error related to password
                        setIsInvalid({
                            ...isInvalid,
                            email: "",
                            password: "Incorrect Password.",
                        });
                    } else {
                        //otherwise it could only be username error
                        setIsInvalid({
                            ...isInvalid,
                            email: "Email not registered with us.",
                            password: "",
                        });
                    }
                } catch {
                    alert(
                        "We're having trouble logging you in! Error: " + error
                    );
                }
                setLoading(false);
            });
    };

    const getProfilePic = async (dataString) => {
        console.log(dataString);
        axios
            .get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getprofilepic/${dataString}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    blobToBase64(res.data)
                        .then((base64String) => {
                            setLocalStorage("profilePic", base64String);
                        })
                        .catch((err) => {
                            toast.error(
                                "Failed to load profile picture, error at blobToBase64"
                            );
                            console.log(err);
                        });
                    console.log(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response?.data?.message);
            });
    };

    // onBlur validator for email field
    const verifyEmail = (e) => {
        if (e.target.value === "") {
            setIsInvalid({ ...isInvalid, email: "Required" });
        } else if (
            !DataForm[e.target.name].match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
        ) {
            setIsInvalid({ ...isInvalid, email: "Invalid Email" });
        }
    };
    // onChange handler for both fields
    const changeHandler = (e) => {
        setIsInvalid({ ...isInvalid, [e.target.name]: "" });
        setDataForm({ ...DataForm, [e.target.name]: e.target.value });
    };

    return (
        <div className={classes.wrapper}>
            <Stack className={classes.form_login}>
                <Image
                    w={100}
                    mx="auto"
                    src="https://www.pinesnutrition.org/wp-content/uploads/2019/01/PINES-LOGO-2-colour-300x170.png"
                ></Image>
                <Text
                    fz={{ base: 24, md: 30 }}
                    fw={800}
                    variant="gradient"
                    gradient={{
                        from: theme.colors.secondary[0],
                        to: theme.colors.primary[0],
                        deg: 135,
                    }}
                    display={"inline"}
                    mx="auto"
                    align="center"
                >
                    Login to Pines
                </Text>
                <SegmentedControl
                    data={["Professional", "Student"]}
                    value={userType}
                    color={theme.colors.primary[0]}
                    onChange={(e) => {
                        console.log(e);
                        setUserType(e);
                    }}
                ></SegmentedControl>
                <TextInput
                    name="email"
                    label="Email"
                    error={isInvalid?.email}
                    required
                    placeholder="Email"
                    onChange={(e) => changeHandler(e)}
                    onBlur={(e) => verifyEmail(e)}
                />
                <PasswordInput
                    name="password"
                    label="Password"
                    error={isInvalid.password}
                    required
                    placeholder="Password"
                    onChange={(e) => changeHandler(e)}
                />
                <Link style={{ textDecoration: "none" }} to="/forgotpassword">
                    <Text fz={"xs"} ps={5} mt={-15} c={"blue"}>
                        Forgot your password?
                    </Text>
                </Link>
                <Button
                    className={classes.button}
                    loading={loading}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    LOGIN
                </Button>
                {Copyright()}
            </Stack>
            <div className={classes.bg} />
        </div>
    );
}
