import React, { useState } from "react";
import { Select, Input, Button, useMantineTheme, Divider } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const SearchSortFilter = ({ onFilterChange }) => {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("Recent");
  const [category, setCategory] = useState("");

  const handleFilterChange = () => {
    onFilterChange({ search, sort, category });
  };

  const handleClearFilters = () => {
    setSearch("");
    setSort("Recent");
    setCategory("");
    onFilterChange({ search: "", sort: "Recent", category: "" });
  };
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  return (
    <>
       <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'stretch' : 'center',
        gap: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        width: '80%',
      }}
    >
      <Input
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ flex: 1 }}
      />
      <Select
        placeholder="Sort"
        data={['Recent', 'Oldest']}
        value={sort}
        onChange={(value) => setSort(value)}
        style={{ flex: 1 }}
      />
      <Select
        placeholder="Category"
        data={[
          "Feature article",
          "Researcher Q & A",
          "Sports Nutritionist Q & A",
          "Olympic Experiences",
          "Food Factsheets",
          "Translated articles",
          "Conference reports",
          "Other",
        ]}
        value={category}
        onChange={(value) => setCategory(value)}
        style={{ flex: 1 }}
      />
      <Button
        onClick={handleFilterChange}
        style={{ flexShrink: 0, width: isMobile ? '100%' : 'auto' }}
        variant="gradient"
        gradient={{ from: "cyan", to: "green", deg: 90 }}
      >
        Apply Filters
      </Button>
      <Button
        variant="outline"
        color="red"
        onClick={handleClearFilters}
        style={{ flexShrink: 0, width: isMobile ? '100%' : 'auto' }}
      >
        Clear Filters
      </Button>
    </div>
    <Divider mt="md" mb="sm" />
    </>
  );
};

export default SearchSortFilter;
