import React, { useEffect, useState } from "react";
import {
    Container,
    Paper,
    Text,
    Button,
    Stack,
    ThemeIcon,
    Title,
    Box,
    Center,
} from "@mantine/core";
import { keyframes } from "@emotion/react";
import { IconCheck } from "@tabler/icons-react";

// Define animation keyframes
const bounce = keyframes({
    "from, 20%, 53%, 80%, to": { transform: "translate3d(0, 0, 0)" },
    "40%, 43%": { transform: "translate3d(0, -20px, 0)" },
    "70%": { transform: "translate3d(0, -15px, 0)" },
    "90%": { transform: "translate3d(0, -4px, 0)" },
});

const shine = keyframes({
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.1)" },
    "100%": { transform: "scale(1)" },
});

const PaymentSuccessPage = () => {
    const [timeLeft, setTimeLeft] = useState(5);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        }
        if (timeLeft === 0) {
            window.location.replace("/");
        }
    }, [timeLeft]);

    return (
        <Container size="xs" h="100vh">
            <Center h="100%">
                <Paper
                    shadow="md"
                    p="xl"
                    radius="md"
                    withBorder
                    sx={{
                        maxWidth: 400,
                        width: "100%",
                    }}
                >
                    <Stack spacing="lg" align="center">
                        <Box
                            sx={{
                                animation: `${bounce} 2s ease infinite`,
                            }}
                        >
                            <ThemeIcon
                                size={80}
                                radius="xl"
                                variant="light"
                                c="green"
                                sx={(theme) => ({
                                    backgroundColor: theme.fn.rgba(
                                        theme.colors.green[6],
                                        0.1
                                    ),
                                    animation: `${shine} 2s ease-in-out infinite`,
                                })}
                            >
                                <IconCheck size={40} />
                            </ThemeIcon>
                        </Box>

                        <Stack spacing="xs" align="center">
                            <Title order={2} align="center">
                                Payment Successful
                            </Title>
                            <Text c="dimmed" size="md" align="center">
                                Thank you for your purchase! Your payment has
                                been processed successfully.
                            </Text>
                        </Stack>

                        <Stack spacing="sm" w="100%">
                            <Button
                                component="a"
                                href="/"
                                variant="filled"
                                color="green"
                                fullWidth
                            >
                                Login
                            </Button>
                        </Stack>

                        <Text size="sm" c="dimmed" align="center">
                            Redirecting to dashboard in{" "}
                            <Text span weight={500}>
                                {timeLeft}
                            </Text>{" "}
                            seconds...
                        </Text>
                    </Stack>
                </Paper>
            </Center>
        </Container>
    );
};

export default PaymentSuccessPage;
