import {
    Badge,
    Button,
    Card,
    Container,
    Divider,
    Flex,
    Grid,
    Group,
    Image,
    Input,
    Pagination,
    Paper,
    Select,
    SimpleGrid,
    Text,
    useMantineTheme,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment/moment";
import { useMediaQuery } from "@mantine/hooks";
import Empty from "../empty";
import { Link } from "react-router-dom";

export default function Vlogs() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [sortOrder, setSortOrder] = useState("newest");
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        getVlogs();
    }, [page]);

    const getVlogs = async () => {
        if (searchTerm != "" || categoryFilter != "" || sortOrder != "newest") {
            setPage(1);
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getvlogs?page=${page}&search=${searchTerm}&order=${sortOrder}&category=${categoryFilter}`
            );
            const { vlogs, totalPages } = response.data;
            setData(vlogs);
            setTotalPages(totalPages);
        } catch (err) {
            toast.error("Failed to load vlogs");
            console.error("Failed to load vlogs", err);
        }
    };

    return (
        <>
            <Container size="xl" my="lg">
                <h1
                    style={{
                        fontSize: "1.875rem", // equivalent to text-3xl
                        fontWeight: 600, // equivalent to font-semibold
                        marginTop: "1.25rem", // equivalent to mt-5
                        marginBottom: "1rem", // equivalent to mb-4
                        marginLeft: "2.5rem", // equivalent to mx-10 (left and right margin)
                        marginRight: "2.5rem",
                    }}
                >
                    Video Resources
                </h1>
                {/* Search, Sort, and Filter Section */}
                <Group
                    position="apart"
                    mb="md"
                    spacing="md"
                    direction="row"
                    style={{ flexWrap: "wrap" }}
                >
                    <Input
                        icon={<IconSearch />}
                        placeholder="Search by title"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ width: "100%", maxWidth: "300px" }}
                    />
                    <Select
                        placeholder="Sort by"
                        data={[
                            { value: "newest", label: "Newest" },
                            { value: "oldest", label: "Oldest" },
                        ]}
                        value={sortOrder}
                        onChange={setSortOrder}
                        style={{ width: "100%", maxWidth: "200px" }}
                    />
                    <Select
                        placeholder="Filter by category"
                        data={[
                            { value: "", label: "All Categories" },
                            {
                                value: "Present & Connect",
                                label: "Present & Connect",
                            },
                            {
                                value: "PINES-ACSM 10 Q&A",
                                label: "PINES-ACSM 10 Q&A",
                            },
                            {
                                value: "PINES Symposium",
                                label: "PINES Symposium",
                            },
                            { value: "Other", label: "Other" },
                        ]}
                        value={categoryFilter}
                        onChange={setCategoryFilter}
                        style={{ width: "100%", maxWidth: "200px" }}
                    />
                    <Button
                        variant="gradient"
                        gradient={{ from: "cyan", to: "green", deg: 90 }}
                        onClick={getVlogs}
                        style={{ width: "100%", maxWidth: "120px" }}
                    >
                        Apply
                    </Button>
                </Group>
                <Divider my="sm" />
            </Container>

            {/* Video Resources Grid */}
            <Grid bg={"white"}>
                {data.length === 0 && <Empty heading={"No videos found"} />}
                {data.map(
                    ({
                        _id,
                        title,
                        thumbnail,
                        author,
                        category,
                        createdAt,
                    }) => (
                        <Grid.Col span={isMobile ? 12 : 4}>
                            <Link
                                to={`/vlog_view?id=${_id}`}
                                style={{
                                    textDecoration: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "10px",
                                }}
                            >
                                <Paper
                                    mx={"sm"}
                                    radius={"lg"}
                                    p="lg"
                                    key={_id}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    td={"none"}
                                    c={"black"}
                                >
                                    <Image
                                        style={{
                                            boxShadow:
                                                "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        }}
                                        radius={"xl"}
                                        alt={title}
                                        src={thumbnail}
                                    />
                                    <Text mx={"lg"} mt={"md"} fw={"bold"}>
                                        {title}
                                    </Text>
                                    <Text mx={"lg"} mb={"md"} c={"dimmed"}>
                                        {author}
                                    </Text>
                                    <Flex>
                                        <Badge
                                            variant="gradient"
                                            gradient={{
                                                from: "cyan",
                                                to: "green",
                                                deg: 90,
                                            }}
                                            mx={"lg"}
                                            size="sm"
                                        >
                                            {category}
                                        </Badge>
                                        <Text
                                            size="sm"
                                            ml={"auto"}
                                            fw={"bold"}
                                            c={"dimmed"}
                                        >
                                            {moment(createdAt).format(
                                                "MMMM Do YYYY"
                                            )}
                                        </Text>
                                    </Flex>
                                </Paper>
                            </Link>
                        </Grid.Col>
                    )
                )}
            </Grid>
            <Pagination
                page={page}
                onChange={setPage}
                total={totalPages}
                color="teal"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                }}
            />
        </>
    );
}
