import React, { useEffect, useState } from "react";
import {
  MantineProvider,
  Container,
  Card,
  Text,
  Button,
  Group,
  Title,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import PayPalButton from "../../components/PayPalButton/PayPalButton";
import { IconBrandStripe, IconCircleCheck } from "@tabler/icons-react";
import styles from "./PaymentPage.module.css"

const SubscriptionRenewalPage = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { email, name, subsPrice, type, endDate } = location.state || {};
  const today = new Date();
  const nextYear = moment(today).add(1, "year").format("MMMM D, YYYY");
  useEffect(() => {
    if (!email || !type || !subsPrice || !endDate) {
      navigate("/");
    }
  }, [email, type, subsPrice, endDate, navigate]);
  const handlePayment = async () => {
    setLoading(true);
    try {
      //it creates the customer id of the new user or with new email
      await axios.post(`${process.env.REACT_APP_BE_URI}/createcustomer`, {
        email,
        name,
        type,
      });
      //now after the creation of the customer id, it creates the checkout with the subs price and product
      const checkout = await axios.post(
        `${process.env.REACT_APP_BE_URI}/createcheckout`,
        {
          email,
          subsPrice,
          type,
        }
      );
      //after the checkout is created, it redirects to the payment page
      if (checkout.data.url) {
        window.open(checkout.data.url, "_self");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className={styles.payment_page}>
    <IconCircleCheck size={50} color="green" />
    <Text
        variant="gradient"
        gradient={{ from: "cyan", to: "green", deg: 90 }}
        fw={700}
        size="xl"
        tt={"capitalize"}
    >
        Congratulations {name} !
    </Text>
    <Text
        variant="gradient"
        gradient={{ from: "cyan", to: "green", deg: 90 }}
        fw={500}
        size="lg"
    >
        Your renewal application has been approved.
    </Text>
    <p>
        You are now ready to initiate your subscription payment. Please
        review the details below before proceeding.
    </p>
    <div className={styles.subscription_details}>
        <Text
            variant="gradient"
            gradient={{ from: "cyan", to: "green", deg: 90 }}
            size="lg"
            fw={700}
            td="underline"
        >
            Subscription Information
        </Text>
        <ul>
            <li>
                <span>Type:</span>{" "}
                {type === "professional" ? "Professional" : "Student"}
            </li>
            <li>
                <span>Amount:</span> ${subsPrice}
            </li>
            <li>
                <span>Recurring:</span> Yearly (next payment due{" "}
                {nextYear})
            </li>
        </ul>
    </div>
    <Group position="center">
        <Button
            onClick={handlePayment}
            loading={loading}
            variant="gradient"
            gradient={{ from: "cyan", to: "green", deg: 90 }}
            size="md"
            fullWidth
            leftSection={<IconBrandStripe size={18} />}
        >
            Pay with Stripe
        </Button>
    </Group>
    {/* paypal component with required props */}
    <div style={{ marginTop: "20px" }}>
        {(
            <PayPalButton
                type={
                    type === "professional" ? "Professional" : "Student"
                }
                amount={subsPrice}
            ></PayPalButton>
        ) || <h1>loading paypal....</h1>}
    </div>
</div>
  );
};

export default SubscriptionRenewalPage;
