export function base64ToBlob(base64, mimeType = 'image/png') {
    // Check if the base64 string contains metadata and strip it
    if (base64.startsWith('data:')) {
      base64 = base64.split(',')[1];
    }
  
    try {
      // Decode Base64 string
      const byteString = atob(base64);
  
      // Create a Uint8Array from the binary string
      const bytes = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        bytes[i] = byteString.charCodeAt(i);
      }
  
      // Create and return a Blob
      return new Blob([bytes], { type: mimeType });
    } catch (error) {
      console.error('Error converting Base64 to Blob:', error);
      throw error; // Re-throw error for handling elsewhere if needed
    }
  }
  