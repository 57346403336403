// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_tabs__Me85u{
    font-size: 18;
}

.Profile_bio__pgzpK *{
    line-height: 120%;
}

.Profile_panel__lzXTI{
    padding: 20px 10px;
    min-height: 70vh;
}

.Profile_editProfile__st1vf{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    border-radius: 50%;
    padding: 0px;
    transform: translate(5px, 5px);
    aspect-ratio: 1 / 1;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Profile/Profile.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".tabs{\n    font-size: 18;\n}\n\n.bio *{\n    line-height: 120%;\n}\n\n.panel{\n    padding: 20px 10px;\n    min-height: 70vh;\n}\n\n.editProfile{\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    z-index: 100;\n    border-radius: 50%;\n    padding: 0px;\n    transform: translate(5px, 5px);\n    aspect-ratio: 1 / 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `Profile_tabs__Me85u`,
	"bio": `Profile_bio__pgzpK`,
	"panel": `Profile_panel__lzXTI`,
	"editProfile": `Profile_editProfile__st1vf`
};
export default ___CSS_LOADER_EXPORT___;
